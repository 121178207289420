import { put, select } from 'redux-saga/effects'
import get from 'lodash/get'

import { showNotification, appLogout } from '../app/actions'
import { isAppLoggedIn } from '../selectors'
import { getApiErrorKey } from '../../lib/miscellaneous'
import { API_ERROR_KEYS } from '../../config'

/**
 * Type Def for the Object Parameter accepted by handleGraphQLApiCall
 * @typedef {Object} handleGraphQLApiCallParams
 * @property {function} apiMethod - The api method to use from our apollo client, either mutate or query
 * @property {string} query - The query to use in the api call (provide either query or mutation!)
 * @property {string} mutation - The mutation to use in the api call (provide either query or mutation!)
 * @property {object} variables - The variables to pass on to the api method
 * @property {function} successAction - The success action to dispatch
 * @property {function} failureAction - The failure action to dispatch
 * @property {string} resultPath - The path to the result data in the graphQL response
 * @property {function} successCallback - A callback to be executed on success, receives the whole response as propertyeter
 * @property {string|string[]} successNotification - Translation key for a custom success notification (failure notifications are handled in appSaga) OR array with [translationKey, type]
 * @property {function} dataMapper - Mapper function to convert result data before storing it (one of lib/dataMapper.js)
 */

/**
 * Function for handling all GraphQL API Calls and auto logout if token invalid
 *
 *  @param {handleGraphQLApiCallParams} handleGraphQLApiCallParams The Object accepted by handleGraphQLApiCall
 */
export function* handleGraphQLApiCall({
  apiMethod,
  query = null,
  mutation = null,
  variables = {},
  successAction,
  failureAction,
  resultPath = '',
  successCallback,
  successNotification,
  dataMapper,
}) {
  try {
    const response = !!query
      ? yield apiMethod({ query, variables })
      : !!mutation
      ? yield apiMethod({ mutation, variables })
      : null

    const result = resultPath ? get(response, resultPath) : response
    if (result) {
      if (successAction) {
        yield put(successAction(dataMapper ? dataMapper(result) : result))
      }
      if (successNotification) {
        Array.isArray(successNotification)
          ? yield put(showNotification(successNotification[0], successNotification[1] || 'success'))
          : yield put(showNotification(successNotification, 'success'))
      }
      if (successCallback) {
        yield successCallback(response)
      }
    } else {
      if (failureAction) {
        yield put(failureAction(response))
      }
    }
  } catch (error) {
    // logout deactivated users
    if (
      error.networkError?.result?.errors?.[0]?.extensions?.code === API_ERROR_KEYS.USER_DEACTIVATED
    ) {
      yield put(appLogout())
      yield put(showNotification('errorKeys.USER_DEACTIVATED', 'error'))
      return
    }

    console.log(
      'Error caught in handleGraphQLApiCall: ',
      {
        apiMethod,
        query,
        mutation,
        variables,
        successAction,
        failureAction,
        resultPath,
        successCallback,
        successNotification,
        dataMapper,
      },
      error
    )

    if (failureAction) {
      yield put(failureAction(error))
    }

    const isLoggedIn = yield select(isAppLoggedIn)

    if (getApiErrorKey(error) === API_ERROR_KEYS.FORBIDDEN && isLoggedIn) {
      yield put(appLogout())
      yield put(showNotification('notification.sessionExpired', 'info'))
    }
  }
}
