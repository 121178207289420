import { Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from '../../lib/translate'
import { autocompleteParse, mapPlacesServiceDetailsResponse } from '../../lib/util'
import { fetchGooglePlacesApiActions } from '../../redux/backend/actions'
import { getGooglePlacesApiData } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  textField: {
    marginBottom: '1.25rem',
  },
  locationIcon: {
    marginRight: theme.spacing(2),
  },
}))

/**
 * Final Form Adapter for Address Autocomplete with Google JavaScript API
 * Takes a Form Mutator which will be called with the mapped details response of the selected location
 */
const AddressAutocompleteAdapter = ({ input, meta, formMutator, ...rest }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const googleApiResponse = useSelector(getGooglePlacesApiData)

  const error = meta.error ? translate(meta.error) : ''

  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [options, setOptions] = useState([])

  /**
   * Memoize and throttle fetch autocomplete to improve performance
   */
  const fetchAutocomplete = useMemo(
    () =>
      throttle(input => {
        dispatch(fetchGooglePlacesApiActions.requestAction({ input }))
      }, 600),
    [dispatch]
  )

  /**
   * If the input value changes, we want to fetch the new autocomplete results
   */
  useEffect(() => {
    if (!input.value) {
      setOptions(autocompleteValue ? [autocompleteValue] : [])
      return undefined
    }
    fetchAutocomplete(input.value)
  }, [autocompleteValue, input.value, fetchAutocomplete])

  /**
   * If the user selects one of the results, we want to fetch the details
   */
  useEffect(() => {
    const placeId = autocompleteValue?.place_id
    if (placeId) {
      dispatch(fetchGooglePlacesApiActions.requestAction({ placeId }))
    }
  }, [autocompleteValue, dispatch])

  /**
   * If the google api response changes, we want to update either the options or call the form mutator
   */
  useEffect(() => {
    if (googleApiResponse?.predictions) {
      let newOptions = []
      if (autocompleteValue) {
        newOptions = [autocompleteValue]
      }
      newOptions = [...newOptions, ...googleApiResponse.predictions]
      setOptions(newOptions)
    }

    if (googleApiResponse?.result?.address_components && formMutator) {
      const addressObj = mapPlacesServiceDetailsResponse(
        googleApiResponse.result.address_components
      )
      formMutator(addressObj)
    }
  }, [
    autocompleteValue,
    formMutator,
    googleApiResponse?.predictions,
    googleApiResponse?.result?.address_components,
  ])

  return (
    <Autocomplete
      {...rest}
      fullWidth
      getOptionLabel={option =>
        typeof option === 'string' ? option : get(option, 'structured_formatting.main_text', '')
      }
      filterOptions={x => x}
      options={options}
      noOptionsText={translate('formFields.noOptions')}
      freeSolo
      autoComplete
      autoHighlight
      value={autocompleteValue || input.value}
      onChange={(event, value) => {
        setOptions(old => (value ? [value, ...old] : old))
        setAutocompleteValue(value)
      }}
      onInputChange={(event, inputValue) => {
        input.onChange(inputValue)
      }}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField}
          helperText={meta.touched ? error : ''}
          error={meta.touched ? meta.invalid : false}
          label={translate('formFields.street')}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={option => {
        const matches = get(option, 'structured_formatting.main_text_matched_substrings', [])
        const parts = autocompleteParse(
          get(option, 'structured_formatting.main_text', ''),
          matches.map(match => [match.offset, match.offset + match.length])
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnOutlinedIcon className={classes.locationIcon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {get(option, 'structured_formatting.secondary_text', '')}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

export default AddressAutocompleteAdapter
