import { Dialog, DialogTitle, Divider, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'

import { useTranslate } from '../../../lib/translate'
import { fetchVerifyVoucherActions } from '../../../redux/actions'
import ApplyVoucherToSavingsPlanDialogContent from './ApplyVoucherToSavingsPlanDialogContent'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const ApplyVoucherToSavingsPlanDialog = ({ open, onClose, flushVoucherData }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const handleClose = () => {
    onClose()
    flushVoucherData()
  }

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {translate('applyVoucherDialog.title')}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <ApplyVoucherToSavingsPlanDialogContent onClose={handleClose} />
    </Dialog>
  )
}

const mapDispatchToProps = {
  flushVoucherData: fetchVerifyVoucherActions.flushAction,
}

export default connect(undefined, mapDispatchToProps)(ApplyVoucherToSavingsPlanDialog)
