import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Card, CardContent, Grid, Collapse, Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import get from 'lodash/get'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'

import { useTranslate } from '../../lib/translate'
import useCalcEmbossingCosts from '../../lib/customHooks/useCalcEmbossingCosts'
import useCountArray from '../../lib/customHooks/useCountArray'
import useCurrency from '../../lib/customHooks/useCurrency'
import ProductField from './ProductField'
import { getBackendUserData } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  card: {
    marginBottom: theme.spacing(3),
  },
}))

const ProductCard = ({ items, title, formValues, formMutators, type, countryDepartment }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const displayCurrency = useCurrency()

  const embossingCosts = useCalcEmbossingCosts(formValues, type, items)
  const [count, { increaseCount, decreaseCount }] = useCountArray(
    get(
      filter(Object.keys(formValues), key => key.includes(type)),
      'length',
      0
    )
  )

  const showItems = !!count.length

  const sortedFilteredItems = orderBy(filter(items, { countryDepartment }), [
    'weightInOz',
    'grossWeight',
  ])

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContentPadding}>
          <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" className={classes.boldText}>
              {title}
            </Typography>
          </Box>
          <div className={`${classes.fieldsDiv}`}>
            {count.map(c => {
              const fieldName = `product-${type}-${c}`
              return (
                <ProductField
                  key={`${type}-item-${c}`}
                  fieldName={fieldName}
                  items={sortedFilteredItems}
                  removable={count.length === c}
                  removeItem={() => {
                    decreaseCount()
                    formMutators.removeItem(fieldName)
                  }}
                />
              )
            })}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                startIcon={<AddIcon />}
                onClick={increaseCount}
              >
                {translate('cancelPlans.addMore')}
              </Button>
            </Grid>
            <Collapse in={showItems} style={{ width: '100%' }}>
              <Grid item xs={12} container justify="space-between">
                <Typography variant="body1" className={classes.greyText}>
                  {translate('cancelPlans.embossingCosts')}
                </Typography>
                <Typography variant="body1" className={classes.boldText}>
                  {displayCurrency(embossingCosts)}
                </Typography>
              </Grid>
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

const mapStateToProps = state => ({
  countryDepartment: getBackendUserData(state)?.countryDepartment,
})

export default connect(mapStateToProps)(ProductCard)
