import { makeStyles } from '@material-ui/core'

const calcAmountFontSize = (defaultSize, amountLength, lengthToStartDecreasing, factor) => {
  if (amountLength >= lengthToStartDecreasing) {
    return `${
      defaultSize - ((Math.min(amountLength, 14) - lengthToStartDecreasing) / factor).toFixed(2)
    }rem`
  }
  return `${defaultSize}rem`
}

export const useSpecialSumStyles = makeStyles(theme => ({
  amount: {
    [theme.breakpoints.only('xl')]: {
      fontSize: props => calcAmountFontSize(3, props.amountLength, 10, 7.5),
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: props => calcAmountFontSize(3, props.amountLength, 4, 6),
    },
    [theme.breakpoints.only('md')]: {
      fontSize: props => calcAmountFontSize(2.5, props.amountLength, 10, 13),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: props => calcAmountFontSize(2.5, props.amountLength, 10, 12),
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: props => calcAmountFontSize(2, props.amountLength, 8, 8),
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))
