import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Dialog, DialogTitle, Box, LinearProgress } from '@material-ui/core'

import { useTranslate } from '../../lib/translate'
import { PriceDevelopmentChart } from '..'
import { EXCHANGE_RATE_RANGES } from '../../config'
import { fetchExchangeRatesActions } from '../../redux/actions'
import { getBackendExchangeRatesRange, isBackendExchangeRatesFetching } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  chartDialogPaper: {
    height: '80vh',
    borderRadius: 15,
  },
  chartDialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  marginRight: {
    marginRight: theme.spacing(),
  },
  selected: {
    fontWeight: theme.typography.fontWeightBold,
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
    },
  },
  chartContainer: {
    height: 'calc(100% - 100px)',
    padding: theme.spacing(2),
  },
}))

const ChartDialog = ({ open, onClose, selectedRange, fetchExchangeRates, isFetching }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const handleClick = range => {
    fetchExchangeRates({ range })
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.chartDialogPaper }}
    >
      <DialogTitle disableTypography className={classes.chartDialogTitle}>
        <Typography variant="h6" className={classes.bold}>
          {translate('chart.overview')}
        </Typography>
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            {Object.values(EXCHANGE_RATE_RANGES).map(itm => (
              <Button
                variant={selectedRange === itm ? 'contained' : 'outlined'}
                color="secondary"
                className={classes.marginRight}
                key={`range-button-${itm}`}
                onClick={() => handleClick(itm)}
              >
                {translate(`chart.${itm}`)}
              </Button>
            ))}
          </Box>
          {isFetching && <LinearProgress color="secondary" />}
        </Box>
        <Button onClick={onClose}>{translate('actions.close')}</Button>
      </DialogTitle>
      <div className={classes.chartContainer}>
        <PriceDevelopmentChart />
      </div>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  selectedRange: getBackendExchangeRatesRange(state),
  isFetching: isBackendExchangeRatesFetching(state),
})

const mapDispatchToProps = {
  fetchExchangeRates: fetchExchangeRatesActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartDialog)
