import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Grid, Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { ROUTES } from '../../config'
import { useTranslate } from '../../lib/translate'
import { PaymentsTable } from '../'
import {
  getBackendInvoicesData,
  getBackendUserData,
  isBackendInvoicesFetching,
} from '../../redux/selectors'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  payoutDepositContainer: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  payoutDepositButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexGrow: 1,
    },
  },
}))

const PaymentsPage = ({ userData, isFetching, invoices }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const isUnderaged = useIsUnderaged()

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} container spacing={3}>
          <Grid item>
            <Typography variant="h6">{translate('formFields.customerId')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.bold}>
              {userData?.customerNumber || translate('dashboard.notAvailable')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.payoutDepositContainer}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RemoveIcon />}
              component={Link}
              to={ROUTES.REQUEST_PAYOUT_STEP_ONE}
              className={classes.payoutDepositButton}
              disabled={isUnderaged}
            >
              {translate('transactions.toPayout')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              component={Link}
              to={ROUTES.CREATE_DEPOSIT_STEP_ONE}
              className={classes.payoutDepositButton}
            >
              {translate('transactions.toDeposit')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <PaymentsTable data={invoices} loading={isFetching} />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userData: getBackendUserData(state),
  isFetching: isBackendInvoicesFetching(state),
  invoices: getBackendInvoicesData(state),
})

export default connect(mapStateToProps)(PaymentsPage)
