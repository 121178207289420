import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { ROUTES } from '../../config'
import { isAppLoggedIn } from '../../redux/selectors'

const PrivateRoute = ({ isLoggedIn, children, ...rest }) => (
  <Route {...rest}>{isLoggedIn ? children : <Redirect to={{ pathname: ROUTES.LOGIN }} />}</Route>
)

const mapStateToProps = state => ({
  isLoggedIn: isAppLoggedIn(state),
})

export default connect(mapStateToProps)(PrivateRoute)
