import { useState, useEffect } from 'react'
import find from 'lodash/find'

/**
 * This custom hook takes care of calculating and setting the product embossing costs
 * CARE: It is specifically written for use in ProductCard.js, so the passed in objects have to have the correct structure!
 */
const useCalcEmbossingCosts = (productFormValues, productType, productItems) => {
  const [state, setState] = useState(0)

  useEffect(() => {
    const newCosts = Object.entries(productFormValues).reduce((result, [k, v]) => {
      if (k.includes(productType) && v?.qty) {
        const itm = find(productItems, { id: v?.id })
        return itm ? ((result += itm.embossingCosts * v.qty), result) : result
      } else {
        return result
      }
    }, 0)
    setState(newCosts)
  }, [productFormValues, productItems, productType])

  return state
}

export default useCalcEmbossingCosts
