import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useTranslate } from '../../lib/translate'

const CustomSaveButton = ({ loading, disabled, onClick, children, ...rest }) => {
  const translate = useTranslate()
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? (
        <CircularProgress color="inherit" size={18} />
      ) : (
        children ?? translate('actions.submit')
      )}
    </Button>
  )
}

CustomSaveButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default CustomSaveButton
