import { ERROR_SOURCE } from '../config'
import logger from './logger'

const loggingErrorHandler = (error, isFatal = false, ...args) =>
  new Promise((resolve, reject) => {
    try {
      logger
        .async(logger.LEVEL.error, {
          isFatal,
          error: error.toString(),
          stack: error.stack,
          args
        })
        .then(resolve)
    } catch (ex) {
      reject(ex)
    }
  })

export const handleError = (error, isFatal, ...args) =>
  Promise.all(loggingErrorHandler(error, isFatal, ...args))

export const handleSagaError = (isRoot = false) => (error, { sagaStack }) =>
  handleError(error, isRoot, {
    source: ERROR_SOURCE.SAGA_MIDDLEWARE,
    sagaStack
  }).finally(() => {
    if (isRoot) {
      logger.error(
        'Unhandled Error in rootSaga, the saga tree was terminated and wont recover from this.'
      )
    } else {
      logger.warn(
        'Unhandled Error in internalRootSaga, Catched before the saga tree was terminated. The saga Middleware should recover from this.'
      )
    }
  })

export default handleError
