import { Button, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useDialog from '../../lib/customHooks/useDialog'
import { useTranslate } from '../../lib/translate'
import { appLogout } from '../../redux/actions'
import { getBackendToken } from '../../redux/selectors'
import InfoDialog from './InfoDialog'

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
    color: theme.palette.secondary.main,
  },
}))

const SessionTimer = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const [isDialogOpen, { openDialog, closeDialog }] = useDialog()
  const token = useSelector(getBackendToken)
  const { exp } = token ? jwt.decode(token) : {}
  const [remainingMS, setRemainingMS] = useState(exp * 1000 - Date.now())
  const duration = moment.duration(remainingMS)

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = exp * 1000 - Date.now()
      setRemainingMS(remaining)
      if (remaining < 0) {
        dispatch(appLogout())
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch, exp])

  return (
    <>
      <Button
        variant="contained"
        className={classes.button}
        onClick={openDialog}
        endIcon={<InfoOutlinedIcon fontSize="small" color="secondary" />}
      >
        {isMobile
          ? moment.utc(duration.as('milliseconds')).format('mm:ss')
          : `${translate('userProfile.appBar.sessionTimer', {
              remainingMin: moment.utc(duration.as('milliseconds')).format('mm:ss'),
            })}`}
      </Button>
      <InfoDialog
        open={isDialogOpen}
        onClose={closeDialog}
        title={translate('dashboard.sessionTimerDialogTitle')}
        infoText={<Typography>{translate('dashboard.sessionTimerDialogContent')}</Typography>}
      />
    </>
  )
}

export default SessionTimer
