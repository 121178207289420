import {
  STORE_CREATE_PLAN_VALUES,
  FLUSH_CREATE_PLAN_VALUES,
  STORE_CANCEL_PLAN_VALUES,
  FLUSH_CANCEL_PLAN_VALUES,
  STORE_REQUEST_PAYOUT_VALUES,
  FLUSH_REQUEST_PAYOUT_VALUES,
  STORE_CREATE_DEPOSIT_VALUES,
  FLUSH_CREATE_DEPOSIT_VALUES,
  STORE_PLAN_ADJUSTMENT_VALUES,
  FLUSH_PLAN_ADJUSTMENT_VALUES
} from './actions'
import { APP_LOGOUT } from '../app/actions'

const initialState = {
  createPlan: {},
  cancelPlan: {},
  requestPayout: {},
  createDeposit: {},
  planAdjustment: {}
}

const formReducer = (state = initialState, action) => {
  const { type, payload, page } = action

  if (type === APP_LOGOUT) {
    return initialState
  } else if (type === STORE_CREATE_PLAN_VALUES) {
    return {
      ...state,
      createPlan: {
        ...state.createPlan,
        [page]: payload
      }
    }
  } else if (type === FLUSH_CREATE_PLAN_VALUES) {
    return {
      ...state,
      createPlan: initialState.createPlan
    }
  } else if (type === STORE_CANCEL_PLAN_VALUES) {
    return {
      ...state,
      cancelPlan: {
        ...state.cancelPlan,
        [page]: payload
      }
    }
  } else if (type === FLUSH_CANCEL_PLAN_VALUES) {
    return {
      ...state,
      cancelPlan: initialState.cancelPlan
    }
  } else if (type === STORE_REQUEST_PAYOUT_VALUES) {
    return {
      ...state,
      requestPayout: {
        ...state.requestPayout,
        [page]: payload
      }
    }
  } else if (type === FLUSH_REQUEST_PAYOUT_VALUES) {
    return {
      ...state,
      requestPayout: initialState.requestPayout
    }
  } else if (type === STORE_CREATE_DEPOSIT_VALUES) {
    return {
      ...state,
      createDeposit: {
        ...state.createDeposit,
        [page]: payload
      }
    }
  } else if (type === FLUSH_CREATE_DEPOSIT_VALUES) {
    return {
      ...state,
      createDeposit: initialState.createDeposit
    }
  } else if (type === STORE_PLAN_ADJUSTMENT_VALUES) {
    return {
      ...state,
      planAdjustment: {
        ...state.planAdjustment,
        [page]: payload
      }
    }
  } else if (type === FLUSH_PLAN_ADJUSTMENT_VALUES) {
    return {
      ...state,
      planAdjustment: initialState.planAdjustment
    }
  }

  return state
}

export default formReducer
