import React from 'react'
import { TextField } from '@material-ui/core'

import { useTranslate } from '../../lib/translate'

const InfoTextField = ({ value, InputProps, ...rest }) => {
  const translate = useTranslate()
  return (
    <TextField
      disabled
      fullWidth
      InputProps={{
        disableUnderline: true,
        style: {
          color: 'black',
        },
        ...InputProps,
      }}
      InputLabelProps={{ shrink: true }}
      value={value || translate('productInfo.notSpecified')}
      style={{ whiteSpace: 'nowrap' }}
      {...rest}
    />
  )
}

export default InfoTextField
