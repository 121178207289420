import { Button, makeStyles, useMediaQuery } from '@material-ui/core'
import get from 'lodash/get'
import { useSnackbar as useStackSnackbar } from 'notistack'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useTranslateApiError from '../../lib/customHooks/useTranslateApiError'
import { useTranslate } from '../../lib/translate'
import { isTranslateable } from '../../lib/util'
import { hideNotification } from '../../redux/actions'
import { getNotification } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  closeButton: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}))

// https://ux.stackexchange.com/questions/85882/for-how-long-should-alerts-be-displayed
const calcDuration = message => Math.min(Math.max(message.length * 50, 2000), 7000)

/**
 * Our custom Snackbar to display toasts for the User
 *
 * Use it with our custom hook useSnackbar!
 * ==> showSnackbar = useSnackbar()
 * ==> showSnackbar(message, type)
 * message: Either error object from api or message string
 * type: one of ["error", "warning", "info", "success"]
 */
const CustomSnackbar = props => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const { enqueueSnackbar, closeSnackbar } = useStackSnackbar()
  const notification = useSelector(getNotification)
  const translateApiError = useTranslateApiError()

  const handleExited = useCallback(() => {
    dispatch(hideNotification())
  }, [dispatch])

  let message = ''
  if (typeof notification?.message === 'object') {
    message = translateApiError(notification.message)
  } else {
    const temp = get(notification, 'message', '')
    message = isTranslateable(temp) ? translate(temp) : temp
  }

  const action = useCallback(
    snackbarId => (
      <Button onClick={() => closeSnackbar(snackbarId)} className={classes.closeButton} />
    ),
    [classes.closeButton, closeSnackbar]
  )

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, {
        action,
        variant: notification?.type || 'info',
        onClose: handleExited,
        autoHideDuration: calcDuration(message),
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transitionDuration: {
          enter: 200,
          exit: 700,
        },
      })
    }
  }, [action, enqueueSnackbar, handleExited, isMobile, message, notification?.type])

  if (!notification) {
    return null
  }

  return <></>
}

export default CustomSnackbar
