import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Dialog, DialogContent, DialogTitle, Divider, Grid } from '@material-ui/core'

import { useTranslate } from '../../lib/translate'
import { PRODUCT_RECORD_TYPE } from '../../config'
import { InfoTextField } from '../'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
  },
}))

const ProductInfoDialog = ({ open, onClose, product }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom className={classes.bold} align="center">
          {translate('productInfo.title')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.bold}>
              {product?.name}
            </Typography>
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <InfoTextField
              label={translate('productInfo.manufacturer')}
              value={product?.manufacturer}
            />
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <InfoTextField
              label={translate('productInfo.originCountry')}
              value={product?.originCountry}
            />
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <InfoTextField label={translate('productInfo.fineness')} value={product?.fineness} />
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <InfoTextField
              label={translate('productInfo.embossingCosts')}
              value={product?.embossingCosts}
            />
          </Grid>
          {product?.recordTypeId === PRODUCT_RECORD_TYPE.GOLD_BAR.id && (
            <Grid item md={3} sm={4} xs={6}>
              <InfoTextField
                label={translate('productInfo.certificate')}
                value={product?.certificate}
              />
            </Grid>
          )}
          <Grid item md={3} sm={4} xs={6}>
            <InfoTextField label={translate('productInfo.weightOz')} value={product?.weightInOz} />
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <InfoTextField
              label={translate('productInfo.grossWeight')}
              value={product?.grossWeight}
            />
          </Grid>
          {product?.recordTypeId === PRODUCT_RECORD_TYPE.GOLD_COIN.id && (
            <>
              <Grid item md={3} sm={4} xs={6}>
                <InfoTextField
                  label={translate('productInfo.diameterMM')}
                  value={product?.diameterMM}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={6}>
                <InfoTextField
                  label={translate('productInfo.thicknessMM')}
                  value={product?.thicknessMM}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: product?.information }} />
          </Grid>
          <Grid item xs={12} container spacing={2} justify="center">
            {product?.productLink1 && (
              <Grid item xs={12} sm={6} md={4}>
                <img
                  alt={translate('productInfo.pic1')}
                  src={product?.productLink1}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </Grid>
            )}
            {product?.productLink2 && (
              <Grid item xs={12} sm={6} md={4}>
                <img
                  alt={translate('productInfo.pic2')}
                  src={product?.productLink2}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </Grid>
            )}
            {product?.productLink3 && (
              <Grid item xs={12} sm={6} md={4}>
                <img
                  alt={translate('productInfo.pic3')}
                  src={product?.productLink3}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ProductInfoDialog
