import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslate } from '../../lib/translate'
import { fetchDeleteAdjustmentActions } from '../../redux/actions'
import { isBackendDeleteAdjustmentFetching } from '../../redux/selectors'
import CustomSaveButton from './CustomSaveButton'
import InfoDialog from './InfoDialog'

const useStyles = makeStyles(theme => ({
  mb: {
    marginBottom: theme.spacing(3),
  },
}))

const DeleteAdjustmentDialog = ({
  open,
  onClose,
  changePlanInfo,
  formattedChangeDate,
  savingPlanLogId,
}) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const isFetching = useSelector(isBackendDeleteAdjustmentFetching)

  const handleSubmit = () => {
    dispatch(fetchDeleteAdjustmentActions.requestAction({ savingPlanLogId }))
  }

  useEffect(() => {
    if (!isFetching) {
      onClose()
    }
  }, [isFetching, onClose])

  return (
    <InfoDialog
      onClose={onClose}
      open={open}
      title={translate('planAdjustment.deleteAdjustment')}
      infoText={
        <Box>
          <Box>
            <Typography className={classes.mb}>
              {translate('planAdjustment.deleteAdjustmentDialogText1')}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {translate('planAdjustment.deleteAdjustmentDialogText2')}
            </Typography>
            <Typography noWrap variant="body2">
              {changePlanInfo}
            </Typography>
            <Typography noWrap variant="body2" component="p">
              {translate('dashboard.fromDate', { date: formattedChangeDate })}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" gridGap={10}>
            <Button onClick={onClose}>{translate('actions.back')}</Button>
            <CustomSaveButton onClick={handleSubmit} loading={isFetching}>
              {translate('actions.submit')}
            </CustomSaveButton>
          </Box>
        </Box>
      }
    />
  )
}

export default DeleteAdjustmentDialog
