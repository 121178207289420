import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Box, Toolbar, Button, IconButton, useMediaQuery, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined'
import SyncIcon from '@material-ui/icons/Sync'
import clsx from 'clsx'

import { useTranslate } from '../../lib/translate'
import { DRAWER_WIDTH, CustomerMessagesButton, CustomerAdvisorDialog } from './../'
import useDialog from '../../lib/customHooks/useDialog'
import useSnackbar from '../../lib/useSnackbar'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDeleteCacheActions } from '../../redux/actions'
import {
  getBackendAccountBrandData,
  getBackendUserData,
  isBackendFetching,
} from '../../redux/selectors'
import SessionTimer from './SessionTimer'

const useStyles = makeStyles(theme => ({
  appBar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonsGap: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiButton-contained': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  customShadow: {
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
  },
  '@keyframes syncAnimation': {
    '0%': {
      '-ms-transform': 'rotate(360deg)',
      '-moz-transform': 'rotate(360deg)',
      '-webkit-transform': 'rotate(360deg)',
      '-o-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
    '100%': {
      '-ms-transform': 'rotate(0deg)',
      '-moz-transform': 'rotate(0deg)',
      '-webkit-transform': 'rotate(0deg)',
      '-o-transform': 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },
  },
  syncRotation: {
    '-webkit-animation': '$syncAnimation 2s linear infinite',
    '-moz-animation': '$syncAnimation 2s linear infinite',
    '-ms-animation': '$syncAnimation 2s linear infinite',
    '-o-animation': '$syncAnimation 2s linear infinite',
    animation: '$syncAnimation 2s linear infinite',
  },
}))

const CustomAppBar = ({ toggleDrawer }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const showSnackbar = useSnackbar()
  const history = useHistory()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const isAnythingFetching = useSelector(isBackendFetching)
  const { partnerId } = useSelector(getBackendUserData) || {}

  const brand = useSelector(getBackendAccountBrandData)
  const [dialogState, { openDialog, closeDialog }] = useDialog()

  const handleDeleteCache = () => {
    dispatch(fetchDeleteCacheActions.requestAction({ history }))
  }

  const handleInfoClick = () => {
    if (brand?.website) {
      window.open(brand.website, '_blank').focus()
    } else {
      showSnackbar('notification.featureNotYetAvailable')
    }
  }

  return (
    <>
      <AppBar className={classes.appBar} elevation={0} position="fixed">
        <Toolbar variant="dense">
          <IconButton
            color="secondary"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box className={classes.buttonsGap}>
              <CustomerMessagesButton />
              {partnerId && (
                <Button
                  variant="contained"
                  className={classes.customShadow}
                  onClick={handleInfoClick}
                >
                  {isMobile ? (
                    <InfoOutlinedIcon color="secondary" />
                  ) : (
                    translate('userProfile.appBar.info')
                  )}
                </Button>
              )}
              <Button
                variant="contained"
                className={classes.customShadow}
                endIcon={!isMobile && <KeyboardVoiceIcon color="secondary" />}
                onClick={openDialog}
              >
                {isMobile ? (
                  <ContactSupportOutlinedIcon color="secondary" />
                ) : (
                  translate('userProfile.appBar.customerAdvisor')
                )}
              </Button>
            </Box>
            <Box className={classes.buttonsGap}>
              <SessionTimer />
              <Tooltip title={translate('userProfile.appBar.syncButton')}>
                <IconButton edge="end" onClick={handleDeleteCache}>
                  <SyncIcon
                    className={clsx({
                      [classes.syncRotation]: isAnythingFetching,
                    })}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <CustomerAdvisorDialog open={dialogState} onClose={closeDialog} />
    </>
  )
}

export default CustomAppBar
