import React, { useEffect, useState } from 'react'
import { MenuItem, Collapse, Box, IconButton, Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import ClearIcon from '@material-ui/icons/Clear'

import { ProductAdapter } from '../'
import { validateProduct } from '../../lib/validation'

const ProductField = ({ fieldName, items, removable, removeItem }) => {
  const [expand, setExpand] = useState(false)

  const handleRemove = () => {
    setExpand(false)
    setTimeout(() => {
      removeItem()
    }, 200)
  }
  useEffect(() => {
    setExpand(true)
  }, [])

  return (
    <Collapse in={expand} timeout={200}>
      <Box marginBottom={3}>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <Field name={fieldName} component={ProductAdapter} validate={validateProduct}>
              {Array.isArray(items) &&
                items.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item xs={1} container alignItems="center">
            {removable && (
              <IconButton onClick={handleRemove}>
                <ClearIcon color="secondary" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  )
}

export default ProductField
