import { ACTION_PREFIX } from '../globalActions'

/**
 * Create Savings Plan Workflow
 */
export const STORE_CREATE_PLAN_VALUES = `${ACTION_PREFIX}/STORE_CREATE_PLAN_VALUES`
export const storeCreatePlanValues = page => payload => ({
  page,
  payload,
  type: STORE_CREATE_PLAN_VALUES
})

export const FLUSH_CREATE_PLAN_VALUES = `${ACTION_PREFIX}/FLUSH_CREATE_PLAN_VALUES`
export const flushCreatePlanValues = () => ({
  type: FLUSH_CREATE_PLAN_VALUES
})

/**
 * Cancel Savings Plan Workflow
 */
export const STORE_CANCEL_PLAN_VALUES = `${ACTION_PREFIX}/STORE_CANCEL_PLAN_VALUES`
export const storeCancelPlanValues = page => payload => ({
  page,
  payload,
  type: STORE_CANCEL_PLAN_VALUES
})

export const FLUSH_CANCEL_PLAN_VALUES = `${ACTION_PREFIX}/FLUSH_CANCEL_PLAN_VALUES`
export const flushCancelPlanValues = () => ({
  type: FLUSH_CANCEL_PLAN_VALUES
})

/**
 * Request Payout Workflow
 */
export const STORE_REQUEST_PAYOUT_VALUES = `${ACTION_PREFIX}/STORE_REQUEST_PAYOUT`
export const storeRequestPayoutValues = page => payload => ({
  page,
  payload,
  type: STORE_REQUEST_PAYOUT_VALUES
})

export const FLUSH_REQUEST_PAYOUT_VALUES = `${ACTION_PREFIX}/FLUSH_REQUEST_PAYOUT`
export const flushRequestPayoutValues = () => ({
  type: FLUSH_REQUEST_PAYOUT_VALUES
})

/**
 * Create Deposit Workflow
 */
export const STORE_CREATE_DEPOSIT_VALUES = `${ACTION_PREFIX}/STORE_CREATE_DEPOSIT`
export const storeCreateDepositValues = page => payload => ({
  page,
  payload,
  type: STORE_CREATE_DEPOSIT_VALUES
})

export const FLUSH_CREATE_DEPOSIT_VALUES = `${ACTION_PREFIX}/FLUSH_CREATE_DEPOSIT`
export const flushCreateDepositValues = () => ({
  type: FLUSH_CREATE_DEPOSIT_VALUES
})

/**
 * Plan Adjustment Workflow
 */
export const STORE_PLAN_ADJUSTMENT_VALUES = `${ACTION_PREFIX}/STORE_PLAN_ADJUSTMENT`
export const storePlanAdjustmentValues = page => payload => ({
  page,
  payload,
  type: STORE_PLAN_ADJUSTMENT_VALUES
})

export const FLUSH_PLAN_ADJUSTMENT_VALUES = `${ACTION_PREFIX}/FLUSH_PLAN_ADJUSTMENT`
export const flushPlanAdjustmentValues = () => ({
  type: FLUSH_PLAN_ADJUSTMENT_VALUES
})
