import { REHYDRATE } from 'redux-persist'

export const ACTION_PREFIX = 'PSC'

export const APP_START = '@@INIT'
export const REDUX_REHYDRATE = REHYDRATE

export const AFTER_REDUX_REHYDRATE = `${ACTION_PREFIX}/AFTER_REDUX_REHYDRATE`
export const afterReduxRehydrate = () => ({
  type: AFTER_REDUX_REHYDRATE
})

export const REDUX_SAGA_INIT = `${ACTION_PREFIX}/REDUX_SAGA_INIT`
export const reduxSagaInit = (initCount = 0, error) => ({
  type: REDUX_SAGA_INIT,
  payload: {
    initCount,
    error
  }
})
