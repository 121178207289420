import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useTranslate } from '../../lib/translate'
import {
  isBackendCreateCustomerMessageFetching,
  isBackendRequestCallbackFetching,
  getBackendCreateCustomerMessageData,
  getBackendCountryDepartmentData,
} from '../../redux/selectors'
import { fetchRequestCallbackActions, fetchCreateCustomerMessageActions } from '../../redux/actions'
import { CustomSaveButton, TextFieldAdapter } from '..'
import { Form, Field } from 'react-final-form'
import { required } from '../../lib/validation'
import { usePrevious } from '../../lib/miscellaneous'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  emailText: {
    fontWeight: 800,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide unmountOnExit direction="up" ref={ref} {...props} />
})

const CustomerAdvisorDialog = ({
  open,
  onClose,
  createMessage,
  requestCallback,
  isFetchingCreateMessage,
  isFetchingRequestCallback,
  createMessageSuccessful,
  countryDepartment,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const wasPreviouslyFetchingCreateMessage = usePrevious(isFetchingCreateMessage)

  const onSubmitForm = ({ title, message }) => {
    createMessage({ title, message })
  }

  const onRequestCallback = () => {
    requestCallback()
  }

  useEffect(() => {
    if (!isFetchingCreateMessage && wasPreviouslyFetchingCreateMessage && createMessageSuccessful) {
      onClose()
    }
  }, [
    createMessageSuccessful,
    isFetchingCreateMessage,
    onClose,
    wasPreviouslyFetchingCreateMessage,
  ])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle disableTypography>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {translate('customerAdvisorDialog.title')}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography>{translate('customerAdvisorDialog.subtitle')}</Typography>
        <Box my={3}>
          <Typography paragraph>{`${translate('customerAdvisorDialog.viaMail')}:`}</Typography>
          <Typography className={classes.emailText} align="center">
            {countryDepartment?.emspEmail}
          </Typography>
        </Box>
        <Divider light />
        <Box my={3}>
          <Typography>{`${translate('customerAdvisorDialog.viaCallback')}:`}</Typography>
          <Typography variant="caption" color="textSecondary" paragraph>
            {translate('customerAdvisorDialog.callbackInfo')}
          </Typography>
          <Box display="flex" justifyContent="center">
            <CustomSaveButton
              size="medium"
              loading={isFetchingRequestCallback}
              onClick={onRequestCallback}
            >
              {translate('customerAdvisorDialog.callback')}
            </CustomSaveButton>
          </Box>
        </Box>
        <Divider light />
        <Box my={3}>
          <Typography paragraph>{`${translate('customerAdvisorDialog.formulaTitle')}:`}</Typography>
          <Form
            onSubmit={onSubmitForm}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="title"
                  component={TextFieldAdapter}
                  validate={required}
                  label={translate('formFields.subject')}
                  fullWidth
                />
                <Field
                  name="message"
                  component={TextFieldAdapter}
                  validate={required}
                  label={translate('formFields.message')}
                  fullWidth
                  multiline
                  rows={6}
                />
                <Box display="flex" justifyContent="flex-end">
                  <CustomSaveButton size="medium" loading={isFetchingCreateMessage}>
                    {translate('actions.submit')}
                  </CustomSaveButton>
                </Box>
              </form>
            )}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  isFetchingRequestCallback: isBackendRequestCallbackFetching(state),
  isFetchingCreateMessage: isBackendCreateCustomerMessageFetching(state),
  createMessageSuccessful: getBackendCreateCustomerMessageData(state),
  countryDepartment: getBackendCountryDepartmentData(state),
})

const mapDispatchToProps = {
  requestCallback: fetchRequestCallbackActions.requestAction,
  createMessage: fetchCreateCustomerMessageActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAdvisorDialog)
