import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

import { getApiBaseUrl } from './config'

/**
 *
 * Setup our Client
 *
 */

/**
 * Create httpLink with baseUrl for all api calls except file uploads
 */
const getHttpLink = () => {
  const baseUrl = getApiBaseUrl()
  return createHttpLink({
    uri: baseUrl,
  })
}
/**
 * Create uploadLink with baseUrl for file uploads
 */
const getUploadLink = () => {
  const baseUrl = getApiBaseUrl()
  return createUploadLink({
    uri: baseUrl,
  })
}

/**
 * Default options to prevent cached results from queries
 */
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
}

/**
 * Create our default client
 */
export const createClientDefault = async () =>
  new ApolloClient({
    link: getHttpLink(),
    cache: new InMemoryCache(),
  })
/**
 * Recreate our client with the login token
 */
export const createClientWithLogin = async token => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }))
  return new ApolloClient({
    link: authLink.concat(getHttpLink()),
    cache: new InMemoryCache(),
    defaultOptions,
  })
}

let _authenticatedClient = null
let _resolveClientPromise = () => undefined
let _clientPromise = null

export const getClient = async (noLogin = false) => {
  if (_authenticatedClient) {
    return _authenticatedClient
  } else if (noLogin) {
    return await createClientDefault()
  }
  if (_clientPromise) {
    return _clientPromise
  }
  _clientPromise = new Promise(resolve => {
    _resolveClientPromise = resolve
  })
  return await _clientPromise
}

export const setClient = client => {
  _authenticatedClient = client
  if (_clientPromise) {
    _resolveClientPromise(_authenticatedClient)
    _clientPromise = null
    _resolveClientPromise = () => undefined
  }
}

export const createUploadClientWithLogin = async token => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }))
  return new ApolloClient({
    link: authLink.concat(getUploadLink()),
    cache: new InMemoryCache(),
  })
}
