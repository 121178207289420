import { EXCHANGE_RATE_RANGES } from '../../../config'
import { SET_CHART_METAL, SET_EXCHANGE_RATE_RANGE, SET_IS_INITIAL_LOGIN, SET_REMAINING_GRAM } from '../actions'

const initialState = {
  isInitialLogin: false,
  remainingGram: null,
  exchangeRateRange: EXCHANGE_RATE_RANGES['3M'],
  chartMetal: null
}

const appReducer = (state = initialState, action) => {
  const { type, payload } = action

  if (type === SET_IS_INITIAL_LOGIN) {
    return {
      ...state,
      isInitialLogin: payload?.isInitialLogin,
    }
  } else if (type === SET_REMAINING_GRAM) {
    return {
      ...state,
      remainingGram: payload?.remainingGram,
    }
  } else if (type === SET_EXCHANGE_RATE_RANGE) {
    return {
      ...state,
      exchangeRateRange: payload?.range,
    }
  } else if (type === SET_CHART_METAL) {
    return {
      ...state,
      chartMetal: payload?.payload?.metal ?? payload?.metal
    }
  }
  return state
}

export default appReducer
