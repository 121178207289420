import React from 'react'
import { Box, Fab, CircularProgress, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  fabWrapper: {
    display: 'flex',
    margin: theme.spacing(1),
    position: 'relative',
  },
  fab: props => ({
    backgroundColor: props.color ?? theme.palette.primary.main,
    color: props.iconColor ?? 'white',
    '&:hover': {
      color: props.color ?? theme.palette.primary.main,
      backgroundColor: props.iconColor ?? 'white',
    },
  }),
  fabProgress: props => ({
    position: 'absolute',
    top: props?.small ? -6 : -7,
    left: props?.small ? -6 : -7,
    zIndex: 1,
    color: props.color ?? theme.palette.primary.main,
  }),
}))

const LoadingIconButton = ({
  small,
  onClick,
  loading,
  disabled,
  icon,
  color,
  iconColor,
  tooltip,
}) => {
  const classes = useStyles({ small, color, iconColor })

  if (!tooltip || loading || disabled) {
    return (
      <Box className={classes.fabWrapper}>
        <Fab
          size={small ? 'small' : 'large'}
          className={classes.fab}
          onClick={onClick}
          disabled={loading || disabled}
        >
          {icon}
        </Fab>
        {loading && <CircularProgress className={classes.fabProgress} size={small ? 52 : 70} />}
      </Box>
    )
  }
  return (
    <Box className={classes.fabWrapper}>
      <Tooltip title={tooltip}>
        <Fab
          size={small ? 'small' : 'large'}
          className={classes.fab}
          onClick={onClick}
          disabled={loading || disabled}
        >
          {icon}
        </Fab>
      </Tooltip>
      {loading && <CircularProgress className={classes.fabProgress} size={small ? 52 : 70} />}
    </Box>
  )
}

export default LoadingIconButton
