import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getBackendAccountBrandData } from '../../redux/selectors'
import createTheme from '../../theme'

const useAppTheme = () => {
  const { primaryColor, secondaryColor } = useSelector(getBackendAccountBrandData) || {}

  const theme = useMemo(() => {
    const primary = primaryColor ? `#${primaryColor}` : null
    const secondary = secondaryColor ? `#${secondaryColor}` : null
    return createTheme(primary, secondary)
  }, [primaryColor, secondaryColor])

  return theme
}

export default useAppTheme
