import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Button } from '@material-ui/core'
import { Form } from 'react-final-form'
import { ROUTES } from '../../../config'
import { FormLayout, CustomSaveButton, ChooseAssetFields } from '../..'
import { useTranslate } from '../../../lib/translate'
import { storeRequestPayoutValues } from '../../../redux/actions'
import { getFormRequestPayoutPageData, getBackendSavingsPlansData } from '../../../redux/selectors'
import { getAvailablePlans } from '../../../lib/util'

export const WORKFLOW_PAGE = 'pageOne'

const RequestPayoutPageOne = ({ values, plans, storeValues }) => {
  const translate = useTranslate()
  const history = useHistory()

  const onSubmit = values => {
    storeValues(values)
    history.push(ROUTES.REQUEST_PAYOUT_STEP_TWO)
  }

  return (
    <FormLayout title={translate('payoutRequest.title')} wideLayout isSignedIn>
      <Form
        onSubmit={onSubmit}
        initialValues={values}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ChooseAssetFields plans={getAvailablePlans(plans)} />
            <Box display="flex" width="100%" justifyContent="space-evenly" mb={5}>
              <Button variant="text" onClick={() => history.goBack()}>
                {translate('cancelPlans.cancel')}
              </Button>
              <CustomSaveButton>{translate('finishRegistration.continue')}</CustomSaveButton>
            </Box>
          </form>
        )}
      />
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  values: getFormRequestPayoutPageData(WORKFLOW_PAGE)(state),
  plans: getBackendSavingsPlansData(state),
})

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeRequestPayoutValues(WORKFLOW_PAGE)(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestPayoutPageOne)
