import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TableCell, Tab, Tabs, TableContainer } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'

export const StyledTableCell = withStyles({
  root: {
    border: 'none',
    whiteSpace: 'nowrap',
  },
})(TableCell)

export const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} variant="scrollable" />)

export const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexGrow: 1,
    },
  },
  selected: {
    color: theme.palette.secondary.main,
  },
}))(props => <Tab {...props} disableRipple />)

export const StyledDatePicker = withStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginRight: theme.spacing(),
    boxShadow: '0px 15px 30px 0px rgba(134, 117, 79, 0.12)',
    transition: 'all 150ms linear',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 2,
    },
  },
}))(props => (
  <DatePicker
    {...props}
    autoOk
    inputProps={{
      style: { textAlign: 'center', cursor: 'pointer' },
    }}
    InputProps={{
      disableUnderline: true,
    }}
  />
))

export const StyledTableContainer = withStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `linear-gradient(to right, ${theme.palette.background.default}, ${theme.palette.background.default}), linear-gradient(to right, ${theme.palette.background.default}, ${theme.palette.background.default}), linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)), linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))`,
      backgroundPosition: 'left center, right center, left center, right center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.background.default,
      backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
      backgroundAttachment: 'local, local, scroll, scroll',
    },
  },
}))(TableContainer)
