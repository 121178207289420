import { Box, Card, Tab, Tabs, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { ResponsiveLine } from '@nivo/line'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import { CURRENCY, METAL_TYPE } from '../../config'
import useCurrency from '../../lib/customHooks/useCurrency'
import { useTranslate } from '../../lib/translate'
import { setChartMetal } from '../../redux/actions'
import {
  getBackendExchangeRatesData,
  getBackendExchangeRatesRange,
  getBackendExchangeRatesTimeFetched,
  getBackendSavingsPlansData,
  getBackendUserData,
  getChartMetal,
  isBackendExchangeRatesFetching
} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  tooltipCard: {
    padding: theme.spacing(),
    borderRadius: 10,
    boxShadow: props => `0 2px 5px 2px ${props.cardShadowColor}`,
    position: 'absolute',
    bottom: theme.spacing(),
    right: props => (props.isFirstHalf ? null : -10),
    left: props => (props.isFirstHalf ? -10 : null),
  },
  tabs: {
    minHeight: 0,
  },
  tabsIndicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 50,
      width: '100%',
      backgroundColor: props => LINE_COLOR_MAPPING[props.chartMetal],
    },
  },
  tab: {
    minWidth: 0,
    minHeight: 0,
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    paddingBottom: 0,
    paddingTop: 0,
    opacity: 0.5,
    '&:focus': {
      opacity: 1,
    },
  },
}))

const LINE_COLOR_MAPPING = {
  [METAL_TYPE.GOLD]: '#D1B000',
  [METAL_TYPE.SILVER]: '#c0c0c0',
  [METAL_TYPE.PLATINUM]: '#d6d5d4',
  [METAL_TYPE.PALLADIUM]: '#BCC1A5',
}

// gather all metal types the user has savingsplans for
const reducedPlansToMetals = plans =>
  Array.isArray(plans)
    ? plans.reduce(
        (result, itm) => (
          !includes(result, itm.metalName?.toLowerCase()) &&
            // eslint-disable-next-line no-sequences
            result.push(itm.metalName?.toLowerCase()),
          result
        ),
        []
      )
    : []

// If user only has one metal, we want to display that chart per default
const defaultSelectedMetal = (plans, id, chartMetal) => {
  if (id) {
    const plan = find(plans, { id })
    return plan?.metalName?.toLowerCase() || METAL_TYPE.GOLD
  } else {
    const temp = reducedPlansToMetals(plans)
    const defaultMetal = temp.length === 1 ? temp[0] : METAL_TYPE.GOLD
    return chartMetal ?? defaultMetal
  }
}

const TooltipCard = ({ point, count }) => {
  const displayCurrency = useCurrency()
  const cardShadowColor = LINE_COLOR_MAPPING[point.serieId].concat('6F')
  const isFirstHalf = point.index < count / 2
  const classes = useStyles({ cardShadowColor, isFirstHalf })
  return (
    <Card className={classes.tooltipCard}>
      <Typography variant="caption" noWrap>
        {`${moment(point.data.x).format('DD.MM.YY')} / ${displayCurrency(point.data.y)}`}
      </Typography>
    </Card>
  )
}

const getLineStyleProps = (dataLength, isSmallScreen, small) => {
  if ((isSmallScreen && dataLength > 40) || (small && dataLength > 150) || dataLength > 300) {
    return {
      lineWidth: 2,
      pointSize: 0,
    }
  }
  return {
    lineWidth: 4,
    pointSize: 5,
    pointColor: '#fff',
    pointBorderWidth: 1,
    pointBorderColor: { from: 'serieColor' },
  }
}

/**
 * This is our Main Chart Component
 * Contains the Tabs for selecting the Metal Type to display
 * Can be rendered in differend ways, see PriceDevelopmentChart
 *
 */
const BasicLineChart = ({ data, plans, small, chartMetal, setChartMetal, ...rest }) => {
  const translate = useTranslate()
  const classes = useStyles({ chartMetal })
  const { id } = useParams()
  const [lineData, setLineData] = useState(data && [find(data, { id: chartMetal })])
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const handleTabChange = (event, newValue) => {
    setChartMetal(newValue)
  }

  useEffect(() => {
    setLineData(data && [find(data, { id: chartMetal })])
  }, [data, chartMetal])

  useEffect(() => {
    setChartMetal(defaultSelectedMetal(plans, id, chartMetal))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box display="flex" alignItems="center">
        <Tabs
          value={chartMetal || METAL_TYPE.GOLD}
          onChange={handleTabChange}
          variant="scrollable"
          TabIndicatorProps={{ children: <span /> }}
          classes={{ root: classes.tabs, indicator: classes.tabsIndicator }}
        >
          <Tab
            disableRipple
            value={METAL_TYPE.GOLD}
            label={translate('metals.gold')}
            className={classes.tab}
          />
          <Tab
            disableRipple
            value={METAL_TYPE.SILVER}
            label={translate('metals.silver')}
            className={classes.tab}
          />
          <Tab
            disableRipple
            value={METAL_TYPE.PLATINUM}
            label={translate('metals.platinum')}
            className={classes.tab}
          />
          <Tab
            disableRipple
            value={METAL_TYPE.PALLADIUM}
            label={translate('metals.palladium')}
            className={classes.tab}
          />
        </Tabs>
      </Box>
      {lineData && (
        <ResponsiveLine
          data={lineData}
          colors={line => LINE_COLOR_MAPPING[line.id] || { scheme: 'spectral' }}
          margin={{ top: 10, right: 5, bottom: 10, left: 10 }}
          xScale={{ type: 'time', format: '%Y-%m-%d' }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          curve="monotoneX"
          axisTop={null}
          axisRight={null}
          useMesh={true}
          crosshairType="cross"
          tooltip={({ point }) => (
            <TooltipCard point={point} count={find(lineData, { id: point.serieId }).data.length} />
          )}
          motionConfig={{
            mass: 1,
            tension: 450,
            friction: 50,
            clamp: true,
            precision: 0.01,
            velocity: 0,
          }}
          {...getLineStyleProps(get(lineData, '[0].data.length', 0), isSmallScreen, small)}
          {...rest}
        />
      )}
    </>
  )
}

/**
 * This Component handles fetching the exchange rates and in which variant the BasicLineChart should be displayed
 *
 */
const PriceDevelopmentChart = ({
  small,
  width,
  exchangeRatesFetchedSuccess,
  plans,
  exchangeRates,
  isFetchingExchangeRates,
  setChartMetal,
  chartMetal,
  currency,
  ...rest
}) => {
  const translate = useTranslate()
  const exchangeRateRange = useSelector(getBackendExchangeRatesRange)

  const data =
    !isEmpty(exchangeRates) &&
    exchangeRates.reduce(
      (prev, next) => {
        prev[findIndex(prev, { id: next.metalName?.toLowerCase() })]?.data?.push({
          x: next.exchangeRateDate,
          y: currency === CURRENCY.EUR ? next.gramPrice : next.gramPriceCHF,
        })
        return prev
      },
      [
        {
          id: METAL_TYPE.GOLD,
          data: [],
        },
        {
          id: METAL_TYPE.SILVER,
          data: [],
        },
        {
          id: METAL_TYPE.PLATINUM,
          data: [],
        },
        {
          id: METAL_TYPE.PALLADIUM,
          data: [],
        },
      ]
    )

  const sortedData =
    data &&
    data.map(element => ({
      ...element,
      data: element.data.sort((a, b) => moment(a.x) - moment(b.x)),
    }))

  if (!exchangeRates) {
    return <Skeleton variant="rect" height="100%" />
  }

  if (small) {
    return (
      <BasicLineChart
        plans={plans}
        data={sortedData}
        enableGridX={false}
        enableGridY={false}
        chartMetal={chartMetal}
        setChartMetal={setChartMetal}
        small
        margin={{ top: 10, right: 20, bottom: 40, left: 45 }}
        axisBottom={{
          format: exchangeRateRange.includes('Y') ? '%Y' : '%d.%m',
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: translate('chart.xAxis'),
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: 5,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: translate('chart.yAxis'),
          legendOffset: -40,
          legendPosition: 'middle',
          tickValues: 5,
        }}
        {...rest}
      />
    )
  }

  return (
    <BasicLineChart
      plans={plans}
      data={sortedData}
      margin={{ top: 20, right: 5, bottom: 50, left: 60 }}
      axisBottom={{
        format: exchangeRateRange.includes('Y') ? '%Y' : '%d.%m',
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: translate('chart.xAxis'),
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: translate('chart.yAxis'),
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      chartMetal={chartMetal}
      setChartMetal={setChartMetal}
      {...rest}
    />
  )
}

const mapStateToProps = state => ({
  plans: getBackendSavingsPlansData(state),
  exchangeRates: getBackendExchangeRatesData(state),
  exchangeRatesFetchedSuccess: getBackendExchangeRatesTimeFetched(state),
  isFetchingExchangeRates: isBackendExchangeRatesFetching(state),
  chartMetal: getChartMetal(state),
  currency: getBackendUserData(state)?.currencyIsoCode,
})

const mapDispatchToProps = {
  setChartMetal,
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceDevelopmentChart)
