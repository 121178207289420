import { ACTION_PREFIX } from './globalActions'

/**
 * An action creator creator. It will return a standard redux action creator, which will put it's arguments inside the returned payload.
 * When you call the action creator creator, you pass it a list of argument names. These names will later end up in the action payload as object keys.
 *
 * Example:
 *   const actionCreator = createAction(AN_ACTION_TYPE, "foo", "cake");
 *       ==> actionCreator equals (arg1, arg2) => ({type: AN_ACTION_TYPE, payload: {foo: arg1, cake: arg2}})
 *
 *   const action = actionCreator("bar", "a lie");
 *       ==> action equals {type: AN_ACTION_TYPE, payload: {foo: "bar", cake: "a lie"}}
 *
 * @function createAction
 * @param type {string} - The type of the action
 * @param argNames {string} - The name of the arguments the actionCreator will be called with. The values passed to the actionCreator will end up in the action payload with their name as their key.
 * @returns {Function} Returns a Redux actionCreator
 */
export const createAction = (type, ...argNames) => {
  if (argNames.length === 0) {
    return (...args) => ({ type, payload: args[0] })
  } else {
    const reducer = (previousValue, currentValue, currentIndex) => {
      return (...args) => ({
        type,
        payload: {
          ...previousValue(...args).payload,
          [currentValue]: args[currentIndex],
        },
      })
    }
    const emptyAction = () => ({ type, payload: {} })
    return argNames.reduce(reducer, emptyAction)
  }
}

/**
 * Type Def for Object returned by createBackendActions
 * @typedef {Object} backendActionObject
 * @property {string} requestType
 * @property {string} successType
 * @property {string} failureType
 * @property {string} flushType
 * @property {function} requestAction
 * @property {function} successAction
 * @property {function} failureAction
 * @property {function} flushAction
 *
 */

/**
 * Backend actions creator creator
 * We take it even further (and shorter) for our backend actions
 * This will return an object containing:
 * - 3 action types and 3 action creators
 * - One for each REQUEST, SUCCESS, FAILURE
 *
 * Example:
 *    const backendAction = createBackendActions('AN_ACTION_TYPE')
 *      => backendAction equals:
 *         {
 *           request: payload => ({ payload, type: requestType})
 *           requestType: 'AN_ACTION_TYPE_REQUEST',
 *           success: payload => ({ payload, timeFetched: new Date(), type: successType })
 *           successType: 'AN_ACTION_TYPE_SUCCESS',
 *           failure: payload => ({ payload, timeFetched: new Date(), type: failureType })
 *           failureType: 'AN_ACTION_TYPE_FAILURE',
 *         }
 *
 *    Then you simply use it like this:
 *      => backendAction.requestAction({ somePayload })
 *    or
 *      => const { requestAction } = backendAction
 *      => requestAction({ somePayload })
 *
 * @function createBackendActions
 * @param {string} type - The type of the action (without the action prefix)
 * @param {function} transform - A function for transforming the requst action payload
 * @returns {backendActionObject} Returns an object containing 3 action types and creators
 */
export const createBackendActions = (type, transform) => {
  const requestType = `${ACTION_PREFIX}/${type}_REQUEST`
  const successType = `${ACTION_PREFIX}/${type}_SUCCESS`
  const failureType = `${ACTION_PREFIX}/${type}_FAILURE`
  const flushType = `${ACTION_PREFIX}/FLUSH_${type}`

  return {
    requestType,
    successType,
    failureType,
    flushType,
    requestAction: payload => ({
      payload: !!transform ? transform(payload) : payload,
      type: requestType,
    }),
    successAction: payload => ({
      payload,
      timeFetched: new Date(),
      type: successType,
    }),
    failureAction: payload => ({
      payload,
      timeFetched: new Date(),
      type: failureType,
    }),
    flushAction: payload => ({
      payload,
      type: flushType,
    }),
  }
}

export default createAction
