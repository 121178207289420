import React from 'react'
import { connect } from 'react-redux'
import { Switch, Redirect, useLocation } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import {
  CustomRoute,
  RequestPayoutPageOne,
  RequestPayoutPageTwo,
  RequestPayoutPageThree,
  RequestPayoutPageFour,
  RequestPayoutPageFive,
} from '../..'
import { ROUTES } from '../../../config'
import { getFormRequestPayoutData } from '../../../redux/selectors'

/**
 * Request Payout Navigation
 *
 * Our nested navigation component for the request payout workflow
 */
const RequestPayoutNavigation = ({ isFormDataMissing }) => {
  const { pathname } = useLocation()

  if (pathname !== ROUTES.REQUEST_PAYOUT_STEP_ONE && isFormDataMissing) {
    return <Redirect to={ROUTES.REQUEST_PAYOUT_STEP_ONE} />
  }

  return (
    <Switch>
      <CustomRoute path={ROUTES.REQUEST_PAYOUT_STEP_ONE}>
        <RequestPayoutPageOne />
      </CustomRoute>
      <CustomRoute path={ROUTES.REQUEST_PAYOUT_STEP_TWO}>
        <RequestPayoutPageTwo />
      </CustomRoute>
      <CustomRoute path={ROUTES.REQUEST_PAYOUT_STEP_THREE}>
        <RequestPayoutPageThree />
      </CustomRoute>
      <CustomRoute path={ROUTES.REQUEST_PAYOUT_STEP_FOUR}>
        <RequestPayoutPageFour />
      </CustomRoute>
      <CustomRoute path={ROUTES.REQUEST_PAYOUT_STEP_FIVE}>
        <RequestPayoutPageFive />
      </CustomRoute>
    </Switch>
  )
}

const mapStateToProps = state => ({
  isFormDataMissing: isEmpty(getFormRequestPayoutData(state)),
})

export default connect(mapStateToProps)(RequestPayoutNavigation)
