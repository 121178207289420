import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { showNotification } from '../redux/actions'

const useSnackbar = () => {
  const dispatch = useDispatch()
  return useCallback(
    (message, type) => {
      dispatch(showNotification(message, type))
    },
    [dispatch]
  )
}

export default useSnackbar
