import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getBackendCountryDepartmentData } from '../../redux/selectors'
import { useSelector } from 'react-redux'

import { useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const SendToAddress = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const countryDepartment = useSelector(getBackendCountryDepartmentData)

  const { streetHouseNr, postalCodeCity, name } = countryDepartment || {}
  return (
    <Box mt={4}>
      <Typography variant="body2" paragraph>
        {translate('createSavingsPlan.sendTo')}
      </Typography>
      <Typography variant="body2" className={classes.bold}>
        {translate('company.name')}
      </Typography>
      <Typography variant="body2" className={classes.bold}>
        {streetHouseNr}
      </Typography>
      <Typography variant="body2" className={classes.bold}>
        {postalCodeCity}
      </Typography>
      <Typography variant="body2" className={classes.bold}>
        {name}
      </Typography>
    </Box>
  )
}

export default SendToAddress
