import config, { LOGLEVEL as LEVEL } from '../config'

const shouldLog = level => {
  if (!config.devEnv) {
    return false
  }
  if (!config.logging.enabledLevels.includes(level)) {
    return false
  }
  return true
}

const _log = (level, ...params) =>
  new Promise(resolve => {
    if (config.devEnv) {
      if (level === LEVEL.debug) {
        console.log('DEBUG', ...params)
      } else {
        console[level](...params)
      }
    }
    resolve()
  })

/**
 * @param  {...any} args
 * @returns undefined Because console.log etc usually returns undefined.
 */
const log = (...args) => {
  _log(...args)
}

const logger = Object.values(LEVEL).reduce(
  (result, level) => {
    result[level] = (...params) => shouldLog(level) && log(level, ...params)
    return result
  },
  {
    async: _log,
    LEVEL
  }
)

export default logger
