import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Badge, useMediaQuery } from '@material-ui/core'
import filter from 'lodash/filter'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import get from 'lodash/get'

import { useTranslate } from '../../lib/translate'
import { getBackendCustomerMessagesData } from '../../redux/selectors'
import useDialog from '../../lib/customHooks/useDialog'
import { CustomerMessagesDialog } from '../'

const useStyles = makeStyles(theme => ({
  customShadow: {
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
  },
}))

const CustomerMessagesButton = ({ messages }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const [dialogState, { openDialog, closeDialog }] = useDialog()

  const newMessagesCount = get(filter(messages, { status: 'New' }), 'length', 0)

  return (
    <>
      <Badge color="secondary" badgeContent={newMessagesCount} invisible={!newMessagesCount}>
        <Button variant="contained" className={classes.customShadow} onClick={openDialog}>
          {isMobile ? (
            <MailOutlineIcon color="secondary" />
          ) : (
            translate('userProfile.appBar.messages')
          )}
        </Button>
      </Badge>
      <CustomerMessagesDialog open={dialogState} onClose={closeDialog} />
    </>
  )
}

const mapStateToProps = state => ({
  messages: getBackendCustomerMessagesData(state),
})

export default connect(mapStateToProps)(CustomerMessagesButton)
