import { Box, Button, Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../config'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'
import { useTranslate } from '../../lib/translate'
import { getAvailablePlans } from '../../lib/util'
import { fetchRefreshTokenActions, storeCancelPlanValues } from '../../redux/actions'
import { getBackendSavingsPlansData } from '../../redux/selectors'
import { WORKFLOW_PAGE } from './cancelSavingsPlan/CancelSavingsPlanPage1'

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContentPadding: {
    padding: theme.spacing(3.75),
  },
  centerCard: {
    display: 'flex',
    minHeight: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: '30rem',
  },
}))

const CancelPlanPage = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const isUnderaged = useIsUnderaged()
  const history = useHistory()
  const dispatch = useDispatch()
  const plans = useSelector(getBackendSavingsPlansData)
  const availablePlans = getAvailablePlans(plans)

  const handleClick = () => {
    if (availablePlans.length === 1) {
      dispatch(storeCancelPlanValues(WORKFLOW_PAGE)({ savingsPlanId: availablePlans[0].id }))
      history.push(ROUTES.CANCEL_SAVINGS_PLAN_STEP_TWO)
    } else {
      history.push(ROUTES.CANCEL_SAVINGS_PLAN_STEP_ONE)
    }
    dispatch(fetchRefreshTokenActions.requestAction())
  }

  return (
    <Box className={classes.centerCard}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContentPadding}>
          <Typography variant="h6" paragraph className={classes.boldText}>
            {translate('cancelPlans.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {translate('cancelPlans.info')}
          </Typography>
          <Button
            color="primary"
            size="medium"
            variant="contained"
            onClick={handleClick}
            disabled={isUnderaged}
          >
            {translate('cancelPlans.button')}
          </Button>
        </CardContent>
      </Card>
    </Box>
  )
}

export default CancelPlanPage
