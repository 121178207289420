import { Box, Button, Card, CardContent, Collapse, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import find from 'lodash/find'
import get from 'lodash/get'
import size from 'lodash/size'
import some from 'lodash/some'
import moment from 'moment'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CodeInput, CustomSaveButton, FormLayout } from '../..'
import useCurrency from '../../../lib/customHooks/useCurrency'
import { hideTelNumber } from '../../../lib/hideData'
import { useTranslate } from '../../../lib/translate'
import { formatIbanForInput } from '../../../lib/util'
import { fetchAdjustPlanActions, fetchSendSecurityCodeActions } from '../../../redux/actions'
import {
  getBackendSavingsPlansData,
  getBackendUserData,
  getFormPlanAdjustmentPageData,
  isBackendAdjustPlanFetching,
  isBackendSendCodeFetching,
} from '../../../redux/selectors'
import { WORKFLOW_PAGE_1 } from './PlanAdjustmentPage1'
import { WORKFLOW_PAGE_2 } from './PlanAdjustmentPage2'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const PlanAdjustmentPageThree = ({
  values,
  plan,
  userData,
  fetchAdjustPlan,
  isFetching,
  fetchSendSecurityCode,
  isFetchingSecurityCode,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const displayCurrency = useCurrency()
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [codeValues, setCodeValues] = useState({})

  const handleSendSecurityCode = () => {
    setShowCodeInput(true)
    fetchSendSecurityCode()
  }

  const onSubmit = () => {
    const securityCode = Object.values(codeValues).join('')
    fetchAdjustPlan({
      ...values,
      history,
      translate,
      securityCode,
      startMonth: moment(values.startMonth).format('YYYY-MM-DD'),
    })
  }

  return (
    <FormLayout title={translate('planAdjustment.title')} wideLayout showClose isSignedIn>
      <Box className={classes.container}>
        <Collapse in={!showCodeInput} unmountOnExit>
          <Card className={classes.card}>
            <CardContent className={classes.cardContentPadding}>
              <Typography variant="h5" paragraph className={classes.boldText}>
                {translate('planAdjustment.totalOverview')}
              </Typography>
              <Box mt={1} mb={3}>
                <Divider />
              </Box>
              <Box mb={2}>
                <Typography variant="h6" className={classes.boldText}>
                  {`${translate('savingsPlans.plan')} ${
                    plan?.metal && translate(`metals.${plan.metalName?.toLowerCase()}`)
                  }`}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography gutterBottom variant="body1">
                  {translate('planAdjustment.actualSavingsRate')}
                </Typography>
                <Typography gutterBottom variant="body1">
                  {displayCurrency(plan?.savingsRate)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography gutterBottom variant="body1">
                  {translate('planAdjustment.newSavingsRate')}
                </Typography>
                <Typography gutterBottom variant="body1">
                  {displayCurrency(values?.newSavingsRate)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography gutterBottom variant="body1">
                  {translate('planAdjustment.start')}
                </Typography>
                <Typography gutterBottom variant="body1">
                  {moment(values?.startMonth).format(translate('formFields.dateFormat'))}
                </Typography>
              </Box>
              <Typography variant="h6" paragraph className={classes.boldText}>
                {translate('planAdjustment.changeBankAccountTitle')}
              </Typography>
              {values.changeBankAccount ? (
                <>
                  <Typography variant="body1" paragraph className={classes.greyText}>
                    {values.bankAccountOwner}
                  </Typography>
                  <Typography variant="body1" paragraph className={classes.greyText}>
                    {values.bank}
                  </Typography>
                  <Typography variant="body1" paragraph className={classes.greyText}>
                    {formatIbanForInput(values.iban)}
                  </Typography>
                  <Typography variant="body1" paragraph className={classes.greyText}>
                    {values.bic}
                  </Typography>
                </>
              ) : (
                <Typography variant="body1" paragraph color="textSecondary">
                  {translate('planAdjustment.noBankAccChange')}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Collapse>
        <Collapse in={showCodeInput} unmountOnExit>
          <CodeInput
            values={codeValues}
            setValues={setCodeValues}
            onSubmit={onSubmit}
            textComponent={
              <Typography variant="body1">
                {translate('planAdjustment.verify2FA', {
                  phone: hideTelNumber(get(userData, 'phone', '')),
                })}
              </Typography>
            }
          />
        </Collapse>
        <Box display="flex" justifyContent="space-between" mt={5}>
          <Button
            variant="text"
            onClick={() => (showCodeInput ? setShowCodeInput(false) : history.goBack())}
          >
            {translate('actions.back')}
          </Button>
          <CustomSaveButton
            onClick={showCodeInput ? onSubmit : handleSendSecurityCode}
            loading={isFetching || isFetchingSecurityCode}
            disabled={
              showCodeInput && (size(codeValues) < 4 || some(codeValues, val => val === ''))
            }
          >
            {showCodeInput ? translate('actions.submit') : translate('actions.continue')}
          </CustomSaveButton>
        </Box>
      </Box>
    </FormLayout>
  )
}

const mapStateToProps = state => {
  const page1Data = getFormPlanAdjustmentPageData(WORKFLOW_PAGE_1)(state)
  const page2Data = getFormPlanAdjustmentPageData(WORKFLOW_PAGE_2)(state)
  const savingsPlanId = get(page1Data, 'savingsPlanId')
  return {
    values: { ...page1Data, ...page2Data },
    plan: find(getBackendSavingsPlansData(state), { id: savingsPlanId }),
    isFetching: isBackendAdjustPlanFetching(state),
    isFetchingSecurityCode: isBackendSendCodeFetching(state),
    userData: getBackendUserData(state),
  }
}

const mapDispatchToProps = {
  fetchAdjustPlan: fetchAdjustPlanActions.requestAction,
  fetchSendSecurityCode: fetchSendSecurityCodeActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanAdjustmentPageThree)
