import get from 'lodash/get'

export const isAppLoggedIn = state => Boolean(get(state, 'app.auth.token'))

export const getNotification = state => get(state, 'app.notifications[0]')

export const getIsInitialLogin = state => get(state, 'app.misc.isInitialLogin', false)

export const getRemainingGram = state => get(state, 'app.misc.remainingGram')

export const getBackendExchangeRatesRange = state => get(state, 'app.misc.exchangeRateRange')

export const getChartMetal = state => get(state, 'app.misc.chartMetal')