import { useState, useMemo } from 'react'

const useListFilterPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedFilter, setSelectedFilters] = useState([])

  const handlers = useMemo(
    () => ({
      openPopover: event => {
        setAnchorEl(event.currentTarget)
      },
      closePopover: () => {
        setAnchorEl(null)
      },
      addFilter: name => {
        setSelectedFilters(old => [...old, name])
      },
      removeFilter: name => {
        setSelectedFilters(old => old.filter(itm => itm !== name))
      },
      resetFilter: () => {
        setSelectedFilters([])
      },
    }),
    []
  )

  return [{ anchorEl, selectedFilter }, handlers]
}

export default useListFilterPopover
