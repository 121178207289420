import { useState, useMemo } from 'react'

const useCountArray = (initialCount = 0) => {
  const [state, setState] = useState(Array.from({ length: initialCount }, (v, i) => i + 1))

  const handlers = useMemo(
    () => ({
      increaseCount: () => {
        setState(oldArray => [...oldArray, oldArray.length + 1])
      },
      decreaseCount: () => {
        setState(oldArray => [...oldArray.slice(0, -1)])
      },
    }),
    []
  )

  return [state, handlers]
}

export default useCountArray
