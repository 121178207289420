import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Field } from 'react-final-form'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Button,
  Snackbar,
  Typography,
  Link,
  Divider,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import { useTranslate } from '../../lib/translate'
import { TextFieldAdapter, CustomSaveButton } from '../'
import config, { ROUTES } from '../../config'
import { appLogin, appLogout } from '../../redux/actions'
import { isBackendLoginFetching, getBackendLoginError, isAppLoggedIn } from '../../redux/selectors'
import { usePrevious } from '../../lib/miscellaneous'
import { required, validateEmail } from '../../lib/validation'
import useSnackbar from '../../lib/useSnackbar'
import useQueryParams from '../../lib/customHooks/useQueryParams'
import useAppLogo from '../../lib/customHooks/useAppLogo'
import { getReferralFromUrl, storeReferralInSessionStorage, getReferralFromSessionStorage } from '../../lib/util'

const useStyles = makeStyles(theme => ({
  centerCard: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    [theme.breakpoints.up('xs')]: {
      width: '27rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
    },
  },
  cardImage: {
    height: '15.625rem',
    objectFit: 'scale-down',
    [theme.breakpoints.down('xs')]: {
      height: '12rem',
    },
  },

  cardContentPadding: {
    padding: theme.spacing(4),
  },
  cardContentNoPadding: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textMargin: {
    margin: '0.5rem auto 3.125rem auto',
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
}))

const isIE = /*@cc_on!@*/ false || !!document.documentMode

const IENotSupportedSnackbar = () => {
  const translate = useTranslate()
  const [showIESnackbar, setShowIESnackbar] = useState(isIE)

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showIESnackbar}>
      <Alert severity="error" variant="filled" onClose={() => setShowIESnackbar(false)}>
        <AlertTitle>{translate('login.warning')}</AlertTitle>
        {translate('login.IEnotSupported')}
      </Alert>
    </Snackbar>
  )
}

const LoginPage = ({ fetchAppLogin, isFetching, error, logout, isLoggedIn }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()
  const wasPreviouslyFetching = usePrevious(isFetching)
  const queryParams = useQueryParams()
  const appLogo = useAppLogo()

  const countryParam = queryParams.get('country')
  const redirectParam = queryParams.get('redirect')

  const registerRoute = `${ROUTES.REGISTER}${countryParam ? `?country=${countryParam}` : ''}`
  const impressumRoute = `${ROUTES.IMPRESSUM}${countryParam ? `?country=${countryParam}` : ''}`
  const privacyPolicyRoute = `${ROUTES.PRIVACY_POLICY}${
    countryParam ? `?country=${countryParam}` : ''
  }`

  let referral = getReferralFromSessionStorage();
  
  if (!referral) {
    referral = getReferralFromUrl();
    if (referral) {
      storeReferralInSessionStorage(referral);
    }
  }

  const onSubmit = ({ email, password }) => {
    fetchAppLogin({ email, password, history })
  }

  useEffect(() => { 
    if (isLoggedIn) {
      logout()
    }
  }, [isLoggedIn, logout])

  useEffect(() => {
    if (wasPreviouslyFetching && !isFetching && error) {
      showSnackbar(error, 'error')
    }
  }, [isFetching, error, wasPreviouslyFetching, showSnackbar])

  return (
    <Box className={classes.centerCard}>
      <Box>
        <Box className={classes.contentContainer}>
          {redirectParam === 'ema' && (
            <Box mb={3}>
              <Card>
                <CardContent>
                  <Typography>{translate('login.redirect')}</Typography>
                </CardContent>
              </Card>
            </Box>
          )}
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Card className={classes.bottomSpacing}>
                  <Box className={classes.cardImage}>
                    <CardMedia
                      component="img"
                      alt="App Logo"
                      image={appLogo}
                      className={classes.cardImage}
                    />
                  </Box>
                  <CardContent className={classes.cardContentPadding}>
                    <Box>
                      <Typography component="h1" variant="h5" className={classes.boldText}>
                        {translate('login.title')}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.textMargin}>
                        {translate('login.subtitle')}
                      </Typography>
                      <Field
                        name="email"
                        component={TextFieldAdapter}
                        validate={validateEmail}
                        fullWidth
                        autoComplete="username"
                        label={translate('formFields.mail')}
                      />
                      <Field
                        name="password"
                        component={TextFieldAdapter}
                        validate={required}
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        label={translate('formFields.pass')}
                      />
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <CustomSaveButton loading={isFetching}>
                        {translate('actions.login')}
                      </CustomSaveButton>
                    </Box>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent className={classes.cardContentNoPadding}>
                    <Box display="flex" justifyContent="space-between">
                      <Button
                        variant="text"
                        className={classes.boldText}
                        component={RouterLink}
                        to={registerRoute}
                      >
                        {translate('actions.register')}
                      </Button>
                      <Button variant="text" component={RouterLink} to={ROUTES.FORGOT_PASSWORD}>
                        {translate('login.forgot')}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </form>
            )}
          />
        </Box>
        <Box mt={4} mb={2}>
          <Divider variant="middle" />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box ml={3}>
            <Link color="textSecondary" href={impressumRoute} target="__blank">
              {translate('drawer.drawerLink.legalInfo')}
            </Link>
          </Box>
          <Box mr={3}>
            <Link color="textSecondary" href={privacyPolicyRoute} target="__blank">
              {translate('drawer.drawerLink.privacyPolicy')}
            </Link>
          </Box>
        </Box>
      </Box>
      <IENotSupportedSnackbar />
      <Box position="absolute" bottom={0} left={2}>
        <Typography style={{ fontSize: '0.5rem' }} variant="caption" color="textSecondary">
          {config.version}
        </Typography>
      </Box>
    </Box>
  )
}

LoginPage.propTypes = {
  fetchAppLogin: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
}

const mapStateToProps = state => ({
  isFetching: isBackendLoginFetching(state),
  error: getBackendLoginError(state),
  isLoggedIn: isAppLoggedIn(state),
})

const mapDispatchToProps = {
  fetchAppLogin: appLogin,
  logout: appLogout,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
