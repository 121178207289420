import { all, takeLatest, put, call, spawn, delay } from 'redux-saga/effects'
import config from '../config'
import logger from '../lib/logger'
import { handleSagaError } from '../lib/errorHandling'
import {
  reduxSagaInit,
  afterReduxRehydrate,
  REDUX_REHYDRATE
} from './globalActions'

import backendSagas from './backend/saga'
import appSagas from './app/saga'

function* afterRehydrationSaga() {
  yield put(afterReduxRehydrate())
}

/**
 * Spreading the sagas is preferred over nesting them
 * https://github.com/redux-saga/redux-saga/issues/160#issuecomment-308540204
 *
 * If they are nested they have to be called as a function
 * all accept any effects (+ promises/awaitables and iterators)
 */
function* internalRootSaga({ count, error }, ...args) {
  logger.info('internalRootSaga', { count, error, args })
  yield all([
    ...backendSagas(...args),
    ...appSagas(...args),
    takeLatest(REDUX_REHYDRATE, afterRehydrationSaga),
    // We want to keep track of saga restarts/recoveries...
    put(reduxSagaInit(count, error))
  ])
}

/**
 * Add another layer to catch all saga errors here before they bubble up
 * to the rootSaga. If erros bubble up to the rootSaga, the complete saga
 * tree will be terminated: https://redux-saga.js.org/docs/basics/ErrorHandling.html
 * @param  {...any} args
 */
function* rootSaga(...args) {
  yield spawn(function* () {
    let error
    let count = 0
    /**
     * During development we want the sagaTree to crash on errors
     */
    if (config.disableSagaErrorHandling) {
      yield call(internalRootSaga, { count, error: undefined }, ...args)
    } else {
      while (true) {
        logger.info('Saga Execution started.', { count, error })
        try {
          yield call(internalRootSaga, { count, error }, ...args)
        } catch (ex) {
          error = ex
          handleSagaError(false)(ex, {
            count,
            delayBeforeRestart: 100 + count * 100 || 100
          })
        }
        yield delay(100 + count * 100 || 100)
        count = count + 1
      }
    }
  })
}

export default rootSaga
