import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography, Button, ButtonBase, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { transformPlanDataForDashboardCard } from '../../lib/transform'
import { ROUTES, SAVING_PLAN_LOG_STATUS } from '../../config'
import { useTranslate } from '../../lib/translate'
import { SavingsPlanCard, SavingPlansTable, SkeletonCard } from '../'
import {
  getBackendSavingPlanLogsData,
  getBackendSavingsPlansData,
  getBackendUserData,
  isBackendSavingPlanLogsFetching,
  isBackendSavingsPlansFetching,
} from '../../redux/selectors'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'
import { sortPlans } from '../../lib/util'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  fontGrey: {
    color: theme.palette.text.hint,
  },
  buttonBase: {
    width: '100% !important',
    height: '100%',
    borderRadius: 10,
    maxWidth: '28rem',
    minHeight: '5rem',
    '&:hover': {
      boxShadow: '0 0 3px #86754F',
    },
  },
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginRight: {
    marginRight: theme.spacing(),
  },
}))

const SavingsPlansOverviewCards = ({ plans, savingPlanLogs }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  const isUnderaged = useIsUnderaged()

  return (
    <Grid item container spacing={2}>
      {Array.isArray(plans) &&
        plans.map(plan => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={plan.id}>
            <SavingsPlanCard
              content={transformPlanDataForDashboardCard(plan)}
              onClick={() => history.push(`${ROUTES.PLAN_DETAILS}/${get(plan, 'id')}`)}
              savingPlanLog={savingPlanLogs.find(({ savingPlanId }) => savingPlanId === plan.id)}
            />
          </Grid>
        ))}
      {!isUnderaged && (
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <ButtonBase
            className={classes.buttonBase}
            component={Link}
            to={ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE}
          >
            <Card className={classes.card}>
              <AddIcon className={classes.marginRight} />
              <Typography>{translate('dashboard.createAnotherPlan')}</Typography>
            </Card>
          </ButtonBase>
        </Grid>
      )}
    </Grid>
  )
}

const SavingsPlansOverview = ({ hasPlans, plans, isFetchingPlans, savingPlanLogs }) => {
  const translate = useTranslate()
  const isUnderaged = useIsUnderaged()
  const sortedPlans = useMemo(() => (hasPlans ? sortPlans(plans) : undefined), [plans, hasPlans])
  const sortedSavingPlanLogs = useMemo(
    () =>
      Array.isArray(savingPlanLogs)
        ? savingPlanLogs
            .filter(({ status }) => status === SAVING_PLAN_LOG_STATUS.OPEN)
            .sort((a, b) => new Date(a.changeDate) - new Date(b.changeDate))
        : [],
    [savingPlanLogs]
  )

  if (isFetchingPlans) {
    return (
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} md={6} xs={12}>
          <SkeletonCard />
        </Grid>
        <Grid item xl={3} lg={4} md={6} xs={12}>
          <SkeletonCard />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      {hasPlans ? (
        <SavingsPlansOverviewCards plans={sortedPlans} savingPlanLogs={sortedSavingPlanLogs} />
      ) : (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            size="large"
            component={Link}
            to={ROUTES.CREATE_SAVINGS_PLAN_STEP_ONE}
            disabled={isUnderaged}
          >
            {translate(`dashboard.createNewPlan`)}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

SavingsPlansOverview.propTypes = {
  hasPlans: PropTypes.bool.isRequired,
  plans: PropTypes.array,
  isFetchingPlans: PropTypes.bool.isRequired,
  savingPlanLogs: PropTypes.array,
}

const SavingsPlansOverviewPage = ({ userData, plans, isFetchingPlans, savingPlanLogs }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const hasExistingPlans = !isEmpty(plans)

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} container spacing={3}>
        <Grid item>
          <Typography variant="h6">{translate('formFields.customerId')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.bold}>
            {userData?.customerNumber || translate('dashboard.notAvailable')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SavingsPlansOverview
          hasPlans={hasExistingPlans}
          plans={plans}
          isFetchingPlans={isFetchingPlans}
          savingPlanLogs={savingPlanLogs}
        />
      </Grid>
      <Grid item xs={12}>
        {hasExistingPlans ? (
          <SavingPlansTable data={plans} loading={isFetchingPlans} />
        ) : (
          <Typography variant="subtitle2">
            {translate('dashboard.transactionTextNoPlans')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  userData: getBackendUserData(state),
  plans: getBackendSavingsPlansData(state),
  isFetchingPlans: isBackendSavingsPlansFetching(state) || isBackendSavingPlanLogsFetching(state),
  savingPlanLogs: getBackendSavingPlanLogsData(state),
})

export default connect(mapStateToProps)(SavingsPlansOverviewPage)
