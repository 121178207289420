import { APP_LOGIN_SUCCESS, APP_LOGOUT } from '../actions'

const initialState = {
  token: null,
}

const authReducer = (state = initialState, action) => {
  const { type, payload } = action
  if (type === APP_LOGIN_SUCCESS) {
    return {
      token: payload?.token,
    }
  } else if (type === APP_LOGOUT) {
    return {
      token: null,
    }
  }
  return state
}

export default authReducer
