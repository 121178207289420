import { CircularProgress, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import { EmailLinkInvalidPage } from '..'
import { GOOGLE_TAG_MANAGER, ROUTES } from '../../config'
import useDataLayer from '../../lib/customHooks/useDataLayer'
import { fetchConfirmNewMailActions, fetchVerifyMailActions } from '../../redux/actions'
import {
  getBackendConfirmNewMailData,
  getBackendConfirmNewMailError,
  getBackendVerifyEmailData,
  getBackendVerifyEmailError,
  isBackendConfirmNewMailFetching,
  isBackendVerifyEmailFetching,
} from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    minHeight: '50vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

export const EMAIL_VERIFICATION_TYPES = {
  REGISTER: 'registerMail',
  CONFIRM_NEW_MAIL: 'confirmNewMail',
}

const TYPE_ACTION_MAPPING = {
  [EMAIL_VERIFICATION_TYPES.REGISTER]: fetchVerifyMailActions.requestAction,
  [EMAIL_VERIFICATION_TYPES.CONFIRM_NEW_MAIL]: fetchConfirmNewMailActions.requestAction,
}

const TYPE_FETCHING_MAPPING = {
  [EMAIL_VERIFICATION_TYPES.REGISTER]: isBackendVerifyEmailFetching,
  [EMAIL_VERIFICATION_TYPES.CONFIRM_NEW_MAIL]: isBackendConfirmNewMailFetching,
}

const TYPE_DATA_MAPPING = {
  [EMAIL_VERIFICATION_TYPES.REGISTER]: getBackendVerifyEmailData,
  [EMAIL_VERIFICATION_TYPES.CONFIRM_NEW_MAIL]: getBackendConfirmNewMailData,
}

const TYPE_ERROR_MAPPING = {
  [EMAIL_VERIFICATION_TYPES.REGISTER]: getBackendVerifyEmailError,
  [EMAIL_VERIFICATION_TYPES.CONFIRM_NEW_MAIL]: getBackendConfirmNewMailError,
}

const EmailVerificationPage = ({ type, verifyAction, isFetching, error, data }) => {
  const { token } = useParams()
  const classes = useStyles()
  const isRegister = type === EMAIL_VERIFICATION_TYPES.REGISTER
  const pushToDataLayer = useDataLayer()

  useEffect(() => {
    if (isRegister) {
      verifyAction({
        token,
        onSuccess: () => pushToDataLayer({ event: GOOGLE_TAG_MANAGER.EVENT.REGISTRATION_VERIFIED }),
      })
    } else {
      verifyAction({ uuid: token })
    }
  }, [isRegister, pushToDataLayer, token, verifyAction])

  if (isFetching || (!data && !error)) {
    return (
      <div className={classes.container}>
        <CircularProgress size={100} />
      </div>
    )
  }

  if (error && isRegister) {
    return <EmailLinkInvalidPage error={error} />
  }

  if (error) {
    return <Redirect to={ROUTES.LOGIN} />
  }

  return <Redirect to={isRegister ? ROUTES.FINISH_REGISTRATION : ROUTES.LOGIN} />
}

EmailVerificationPage.propTypes = {
  verifyAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.object,
  data: PropTypes.object,
}

const mapStateToProps = (state, { type }) => {
  const fetchingFunc = TYPE_FETCHING_MAPPING[type]
  const dataFunc = TYPE_DATA_MAPPING[type]
  const errorFunc = TYPE_ERROR_MAPPING[type]
  return {
    isFetching: fetchingFunc(state),
    data: dataFunc(state),
    error: errorFunc(state),
  }
}

const mapDispatchToProps = (dispatch, { type }) => {
  const verifyAction = TYPE_ACTION_MAPPING[type]
  return {
    verifyAction: props => dispatch(verifyAction(props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage)
