import { responsiveFontSizes } from '@material-ui/core/styles'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'

const createTheme = (primaryColor, secondaryColor) =>
  responsiveFontSizes(
    createMuiTheme({
      palette: {
        primary: {
          main: primaryColor ?? '#002052',
        },
        secondary: {
          main: secondaryColor ?? '#86754F',
        },
        background: {
          default: '#FCFBF8',
          paper: '#fff',
        },
      },
      typography: {
        fontFamily: 'Apercu',
      },
      overrides: {
        MuiButton: {
          root: {
            textTransform: 'none',
            borderRadius: '8px',
          },
        },
        MuiCard: {
          root: {
            borderRadius: '5px',
            boxShadow: '0px 15px 30px 0px rgba(134, 117, 79, 0.12)',
          },
        },
        MuiLink: {
          root: {
            cursor: 'pointer',
          },
        },
        MuiListItemText: {
          inset: {
            paddingLeft: '2rem',
          },
        },
        MuiSlider: {
          thumb: {
            width: '24px',
            height: '24px',
            marginTop: '-12px',
            marginLeft: '-12px',
          },
        },
        MuiPickersDay: {
          daySelected: {
            backgroundColor: '#202020',
          },
        },
        MuiPickersSlideTransition: {
          transitionContainer: {
            '& > *': { fontWeight: 600 },
          },
        },
        MuiPickersCalendar: {
          transitionContainer: {
            marginBottom: '12px',
          },
          week: {
            '& .MuiIconButton-root:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
        MuiPickersModal: {
          dialogRoot: {
            '& .MuiDialogActions-root': {
              display: 'none',
            },
          },
        },
      },
      props: {
        MuiSkeleton: {
          animation: 'wave',
        },
      },
    })
  )

export default createTheme
