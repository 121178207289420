import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ROUTES } from '../../config'
import { getBackendCountryDepartmentData } from '../../redux/selectors'

const useDrawerGreyItems = () => {
  const countryDepartment = useSelector(getBackendCountryDepartmentData)

  const { countryCode } = countryDepartment || {}

  const handlers = useMemo(
    () => ({
      handleLegalInfoClick: () => {
        window.open(`${ROUTES.IMPRESSUM}?country=${countryCode}`, '_blank').focus()
      },
      handleBGBClick: () => {
        window.open(`${ROUTES.BGB}?country=${countryCode}`, '_blank').focus()
      },
      handlePrivacyPolicyClick: () => {
        window.open(`${ROUTES.PRIVACY_POLICY}?country=${countryCode}`, '_blank').focus()
      },
      handleTosClick: () => {
        window.open(`${ROUTES.TOS}?country=${countryCode}`, '_blank').focus()
      },
      handlePrivacyInfoClick: () => {
        window.open(`${ROUTES.PRIVACY_INFO}?country=${countryCode}`, '_blank').focus()
      },
    }),
    [countryCode]
  )

  return [
    {
      key: 'drawer.drawerLink.tos',
      handleClick: handlers.handleTosClick,
    },
    {
      key: 'drawer.drawerLink.bgb',
      handleClick: handlers.handleBGBClick,
    },
    {
      key: 'drawer.drawerLink.privacyInfo',
      handleClick: handlers.handlePrivacyInfoClick,
    },
    {
      key: 'drawer.drawerLink.legalInfo',
      handleClick: handlers.handleLegalInfoClick,
    },
    {
      key: 'drawer.drawerLink.privacyPolicy',
      handleClick: handlers.handlePrivacyPolicyClick,
    },
  ]
}

export default useDrawerGreyItems
