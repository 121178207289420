import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import authReducer from './authReducer'
import notificationReducer from './notificationReducer'
import miscReducer from './miscReducer'

// List of all app reducers
const appReducerList = {
  auth: authReducer,
  notifications: notificationReducer,
  misc: miscReducer,
}

/**
 * Persist config
 * We want to persist the JWT
 */
const persistConfig = {
  key: 'app',
  storage,
  whitelist: ['auth'],
}

/**
 * The app reducer just combines all sub reducers
 */
const combinedReducers = combineReducers(appReducerList)

/**
 * Persist the app reducer
 */
const appReducer = persistReducer(persistConfig, combinedReducers)

export default appReducer
