export const sanitizeUserDataForForm = ({
  url,
  type,
  customer,
  newCustomer,
  email,
  emailUserAccount,
  emailValidationTime,
  resetPasswordTimestamp,
  X2FA_validationTimestamp,
  updated_at,
  __typename,
  phone,
  attributes,
  id,
  resetPasswordValidationTimestamp,
  isVerified,
  verificationFile,
  WFVerificationFile,
  country,
  countryCode,
  newVerificationFileFieldNotUsed,
  ...rest
}) => ({ ...rest, country: countryCode })

export const sanitizeContractorForForm = ({
  url,
  type,
  customer,
  newCustomer,
  emailUserAccount,
  emailValidationTime,
  resetPasswordTimestamp,
  X2FA_validationTimestamp,
  updated_at,
  __typename,
  attributes,
  id,
  resetPasswordValidationTimestamp,
  isVerified,
  verificationFile,
  WFVerificationFile,
  country,
  countryCode,
  newVerificationFileFieldNotUsed,
  ...rest
}) => ({ ...rest, country: countryCode })
