import { useRef, useEffect } from 'react'
import get from 'lodash/get'
import find from 'lodash/find'
import includes from 'lodash/includes'

import { API_ERROR_KEYS } from '../config'

export const getApiErrorKey = error => {
  const code = get(error, 'graphQLErrors[0].extensions.code')
  return code === API_ERROR_KEYS.SALESFORCE_ERROR
    ? get(error, 'graphQLErrors[0].extensions.extensions.code')
    : code
}

export const getApiErrorTranslationKey = error => {
  const errorKey = getApiErrorKey(error)
  if (errorKey) return `errorKeys.${errorKey}`
  return 'errorKeys.default'
}

export const countryToFlag = isoCode =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode

// Hook
export const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

// https://create-react-app.dev/docs/code-splitting/
export const getDialCodeFromPhoneNumber = phone => {
  import('../config/countries')
    .then(({ countries }) => {
      return find(countries, obj => includes(phone, obj.dial_code))
    })
    .catch(error => {
      console.warn('Error while trying to import countries: ', error)
    })
}

export const formatNumber = (
  number,
  digits = 2,
  options
) =>
  (number || number === 0 ? number : 0).toLocaleString('de-DE', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    ...options,
  })

export const formatGram = (gram) => formatNumber(gram, 4, { style: 'unit', unit: 'gram' })

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)
