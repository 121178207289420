import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Button,
  Typography,
  Box,
  Collapse,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core'
import some from 'lodash/some'
import size from 'lodash/size'

import {
  TextFieldAdapter,
  DialingCodeAdaper,
  CustomSaveButton,
  PasswordSafetyIndicator,
  CodeInput,
} from '..'
import { validateSecurityPageForm } from '../../lib/validation'
import {
  fetchChangeSecurityDetailsActions,
  fetchSendSecurityCodeActions,
  fetchConfirmNewPhoneActions,
} from '../../redux/actions'
import get from 'lodash/get'
import { useTranslate } from '../../lib/translate'
import {
  getBackendSendCodeData,
  isBackendChangeSecurityDetailsFetching,
  isBackendSendCodeFetching,
  getBackendChangeSecurityDetailsError,
  isBackendConfirmNewPhoneFetching,
  getBackendUserData,
} from '../../redux/selectors'
import { usePrevious } from '../../lib/miscellaneous'
import { DisplayField } from './UserProfilePage'

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  passwordHint: {
    color: theme.palette.text.hint,
  },
  collapseContainer: {
    width: '100%',
  },
  appbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  contentContainer: {
    width: '50rem',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    },
  },
  codeInputContainer: {
    width: '30rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    },
  },
  card: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  cardContent: {
    padding: theme.spacing(4),
  },
  passwordSafetyContainer: {
    display: 'grid',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,
  },
}))

const SecurityDetailsCard = () => {
  const translate = useTranslate()
  const userData = useSelector(getBackendUserData)
  const { phone, email } = userData || {}

  return (
    <Card>
      <CardHeader title={translate('userProfile.contactInfo')} />
      <CardContent>
        <DisplayField label={translate('formFields.phone')} value={phone} />
        <DisplayField label={translate('formFields.mail')} value={email} />
      </CardContent>
    </Card>
  )
}

const SecurityPage = ({
  fetchChangeSecurityDetails,
  fetchSendSecurityCode,
  fetchConfirmNewPhone,
  isFetching,
  error,
  isFetchingCode,
  sendCodeSuccess,
  isFetchingConfirmNewPhone,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const history = useHistory()
  const wasPreviouslyFetching = usePrevious(isFetching)
  const wasPreviouslyFetchingCode = usePrevious(isFetchingCode)

  const [tabValue, setTabValue] = useState(null)
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [submitValues, setSubmitValues] = useState(null)
  const [codeValues, setCodeValues] = useState({})

  const [confirmNewPhone, setConfirmNewPhone] = useState(false)
  const [codeValuesNewPhone, setCodeValuesNewPhone] = useState({})

  const handleFirstSubmit = ({ dialCode, phoneNr, ...values }) => {
    const phone = get(dialCode, 'dial_code', '').concat(phoneNr)
    setSubmitValues({ ...values, phone })
    fetchSendSecurityCode()
  }

  const handleCompleteSubmit = () => {
    const { password, email, phone } = submitValues || {}
    const code = Object.values(codeValues).join('')
    if (tabValue === 1) {
      fetchChangeSecurityDetails({ history, code, password })
    } else if (tabValue === 2) {
      fetchChangeSecurityDetails({ history, code, email })
    } else if (tabValue === 3) {
      fetchChangeSecurityDetails({ history, code, phone })
    }
  }

  const handleConfirmNewPhone = () => {
    const code = Object.values(codeValuesNewPhone).join('')
    fetchConfirmNewPhone({ history, code })
  }

  useEffect(() => {
    if (!isFetchingCode && wasPreviouslyFetchingCode && sendCodeSuccess) {
      setShowCodeInput(true)
    }
  }, [isFetchingCode, sendCodeSuccess, wasPreviouslyFetchingCode])

  useEffect(() => {
    if (!isFetching && wasPreviouslyFetching && !error) {
      setConfirmNewPhone(true)
    }
  }, [error, isFetching, wasPreviouslyFetching])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item className={classes.buttonContainer}>
          <Button
            variant={tabValue === 1 ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setTabValue(1)}
            fullWidth
          >
            {translate('security.changePassword')}
          </Button>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Button
            variant={tabValue === 2 ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setTabValue(2)}
            fullWidth
          >
            {translate('security.changeEmail')}
          </Button>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Button
            variant={tabValue === 3 ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setTabValue(3)}
            fullWidth
          >
            {translate('security.changePhone')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={!tabValue} unmountOnExit>
            <SecurityDetailsCard />
          </Collapse>
        </Grid>
      </Grid>
      <Collapse in={!!tabValue} unmountOnExit>
        <Grid container>
          <Collapse in={!showCodeInput} className={classes.collapseContainer}>
            <Grid item xs={12} container direction="column" alignItems="center">
              <Form
                onSubmit={handleFirstSubmit}
                validate={values => validateSecurityPageForm(values, tabValue)}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <Box className={classes.contentContainer}>
                      <Collapse in={tabValue === 1} unmountOnExit>
                        <Card className={classes.card}>
                          <CardContent className={classes.cardContent}>
                            <Box>
                              <Field
                                name="password"
                                component={TextFieldAdapter}
                                fullWidth
                                type="password"
                                label={translate('formFields.newPassword')}
                              />
                              <Field
                                name="confirmPassword"
                                component={TextFieldAdapter}
                                fullWidth
                                type="password"
                                label={translate('formFields.confirmNewPassword')}
                              />
                              <Box className={classes.passwordSafetyContainer}>
                                <Typography variant="subtitle2">
                                  {translate('register.security')}
                                </Typography>
                                <PasswordSafetyIndicator password={get(values, 'password')} />
                              </Box>
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  paragraph
                                  className={classes.passwordHint}
                                >
                                  {translate('register.passwordHint')}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Collapse>
                      <Collapse in={tabValue === 2} unmountOnExit>
                        <Card className={classes.card}>
                          <CardContent className={classes.cardContent}>
                            <Field
                              name="email"
                              component={TextFieldAdapter}
                              fullWidth
                              label={translate('formFields.mail')}
                            />
                          </CardContent>
                        </Card>
                      </Collapse>
                      <Collapse in={tabValue === 3} unmountOnExit>
                        <Card className={classes.card}>
                          <CardContent className={classes.cardContent}>
                            <Grid container spacing={1}>
                              <Grid item xs={3}>
                                <Field name="dialCode" component={DialingCodeAdaper} fullWidth />
                              </Grid>
                              <Grid item xs={9}>
                                <Field
                                  name="phoneNr"
                                  component={TextFieldAdapter}
                                  type="tel"
                                  label={translate('formFields.phone')}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Collapse>
                      <Box display="flex" justifyContent="space-between">
                        <Button onClick={() => setTabValue(null)}>
                          {translate('actions.back')}
                        </Button>
                        <CustomSaveButton loading={isFetchingCode}>
                          {translate('finishRegistration.continue')}
                        </CustomSaveButton>
                      </Box>
                    </Box>
                  </form>
                )}
              />
            </Grid>
          </Collapse>
          <Collapse
            in={showCodeInput && !confirmNewPhone}
            unmountOnExit
            className={classes.collapseContainer}
          >
            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
              <Box className={classes.codeInputContainer}>
                <CodeInput
                  values={codeValues}
                  setValues={setCodeValues}
                  onSubmit={handleCompleteSubmit}
                />
                <Box display="flex" justifyContent="space-between" mt="2rem">
                  <Button onClick={() => setShowCodeInput(false)}>
                    {translate('actions.back')}
                  </Button>
                  <CustomSaveButton
                    disabled={size(codeValues) < 4 || some(codeValues, val => val === '')}
                    loading={isFetching}
                    onClick={handleCompleteSubmit}
                  >
                    {translate('twoFactorAuthCheck.sendButton')}
                  </CustomSaveButton>
                </Box>
              </Box>
            </Box>
          </Collapse>
          <Collapse in={confirmNewPhone} unmountOnExit className={classes.collapseContainer}>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
              <Typography component="h3" paragraph className={classes.boldText}>
                {translate('security.confirmNewPhoneTitle')}
              </Typography>
              <Box className={classes.codeInputContainer}>
                <CodeInput
                  values={codeValuesNewPhone}
                  setValues={setCodeValuesNewPhone}
                  onSubmit={handleConfirmNewPhone}
                  noResendCode
                  textComponent={
                    <Typography variant="body1">
                      {translate('security.confirmPhoneInfo', {
                        phone: get(submitValues, 'phone', ''),
                      })}
                    </Typography>
                  }
                />
                <Box display="flex" justifyContent="flex-end" mt="2rem">
                  <CustomSaveButton
                    disabled={
                      size(codeValuesNewPhone) < 4 || some(codeValuesNewPhone, val => val === '')
                    }
                    loading={isFetchingConfirmNewPhone}
                    onClick={handleConfirmNewPhone}
                  >
                    {translate('twoFactorAuthCheck.sendButton')}
                  </CustomSaveButton>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </Grid>
      </Collapse>
    </>
  )
}

const mapStateToProps = state => ({
  isFetching: isBackendChangeSecurityDetailsFetching(state),
  error: getBackendChangeSecurityDetailsError(state),
  isFetchingCode: isBackendSendCodeFetching(state),
  sendCodeSuccess: !!getBackendSendCodeData(state),
  isFetchingConfirmNewPhone: isBackendConfirmNewPhoneFetching(state),
})

const mapDispatchToProps = {
  fetchChangeSecurityDetails: fetchChangeSecurityDetailsActions.requestAction,
  fetchSendSecurityCode: fetchSendSecurityCodeActions.requestAction,
  fetchConfirmNewPhone: fetchConfirmNewPhoneActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPage)
