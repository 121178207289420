import { Box, Button } from '@material-ui/core'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ChooseAssetFields, CustomSaveButton, FormLayout } from '../..'
import { ROUTES } from '../../../config'
import { useTranslate } from '../../../lib/translate'
import { getAvailablePlans } from '../../../lib/util'
import { storeCancelPlanValues } from '../../../redux/actions'
import { getBackendSavingsPlansData, getFormCancelPlanPageData } from '../../../redux/selectors'

export const WORKFLOW_PAGE = 'pageOne'

const CancelSavingsPlanPageOne = ({ initialValues, storeValues, plans }) => {
  const translate = useTranslate()
  const history = useHistory()

  const onSubmit = values => {
    storeValues(values)
    history.push(ROUTES.CANCEL_SAVINGS_PLAN_STEP_TWO)
  }

  return (
    <FormLayout title={translate('cancelPlans.cancelPlan')} wideLayout isSignedIn>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ChooseAssetFields plans={getAvailablePlans(plans)} />
            <Box display="flex" width="100%" justifyContent="space-evenly" mb={5}>
              <Button variant="text" onClick={() => history.goBack()}>
                {translate('cancelPlans.cancel')}
              </Button>
              <CustomSaveButton>{translate('finishRegistration.continue')}</CustomSaveButton>
            </Box>
          </form>
        )}
      />
    </FormLayout>
  )
}

const mapStateToProps = state => ({
  initialValues: getFormCancelPlanPageData(WORKFLOW_PAGE)(state),
  plans: getBackendSavingsPlansData(state),
})

const mapDispatchToProps = dispatch => ({
  storeValues: values => dispatch(storeCancelPlanValues(WORKFLOW_PAGE)(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CancelSavingsPlanPageOne)
