import { Box, Grid, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import React from 'react'
import { Field } from 'react-final-form'

import { SelectAdapter } from '../'
import { useTranslate } from '../../lib/translate'
import { required } from '../../lib/validation'

const useStyles = makeStyles(theme => ({
  minAmount: {
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(1.6),
    marginRight: theme.spacing(1.6),
  },
  fieldBg: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
  },
}))

const ChooseAssetFields = ({ plans = [] }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Box mt={10} mb={10}>
      <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Field
            name="savingsPlanId"
            component={SelectAdapter}
            validate={required}
            label={translate('cancelPlans.fields.savingsPlan')}
            fullWidth
            className={classes.fieldBg}
          >
            {plans.map(plan => (
                <MenuItem value={plan.id} key={`select-item-${plan.id}`}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item xs={8}>
                      {get(plan, 'customName') ?? get(plan, 'name')}
                    </Grid>
                    <Grid item xs={3}>
                      {plan.metalName ? translate(`metals.${plan.metalName?.toLowerCase()}`) : null}
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
          </Field>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ChooseAssetFields
