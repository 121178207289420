import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslate } from '../../lib/translate'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}))

const DeleteRequestDialog = ({ open, onClose, onSubmit }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {translate('deleteRequestDialog.title')}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography align="center">{translate('deleteRequestDialog.subtitle')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {translate('actions.back')}
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained">
          {translate('actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteRequestDialog
