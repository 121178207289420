import replace from 'lodash/replace'

export const hideEmail = email =>
  email ? replace(email, email.substring(1, email.indexOf('@')), '****') : ''

export const hideTelNumber = telNumber =>
  telNumber
    ? `${telNumber.substring(0, 3)}*****${telNumber.substring(
        telNumber.length - 4
      )}`
    : ''
