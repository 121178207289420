import { Box, Button, Card, CardContent, Collapse, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { CustomSaveButton, FormLayout, TextFieldAdapter } from '..'
import { API_ERROR_KEYS, ROUTES } from '../../config'
import { getApiErrorKey, getApiErrorTranslationKey } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'
import { validateEmail } from '../../lib/validation'
import { fetchResendVerifyMailActions } from '../../redux/actions'
import { isBackendResendVerifyMailFetching } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '10px',
    padding: theme.spacing(0.5),
  },
  formSubmitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const EmailLinkInvalidPage = ({ error, isFetching, resendMail }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [showEmailField, setShowEmailField] = useState(false)

  const handleResendMail = ({ email }) => {
    resendMail({ email })
  }

  return (
    <FormLayout>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" align="center" color="error" paragraph>
            {translate(getApiErrorTranslationKey(error))}
          </Typography>
          {getApiErrorKey(error) !== API_ERROR_KEYS.ALREADY_VERIFIED ? (
            <>
              <Collapse in={!showEmailField}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setShowEmailField(true)}
                >
                  {translate('finishRegistration.sendNewMail')}
                </Button>
              </Collapse>
              <Collapse in={showEmailField}>
                <Form
                  onSubmit={handleResendMail}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="email"
                        component={TextFieldAdapter}
                        validate={validateEmail}
                        fullWidth
                        label={translate('formFields.mail')}
                      />
                      <Box className={classes.formSubmitContainer}>
                        <CustomSaveButton loading={isFetching}>
                          {translate('actions.submit')}
                        </CustomSaveButton>
                      </Box>
                    </form>
                  )}
                />
              </Collapse>
            </>
          ) : null}
        </CardContent>
        <Button variant="text" component={Link} to={ROUTES.LOGIN}>
          {translate('finishRegistration.backToLogin')}
        </Button>
      </Card>
    </FormLayout>
  )
}

EmailLinkInvalidPage.propTypes = {
  error: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  resendMail: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isFetching: isBackendResendVerifyMailFetching(state),
})

const mapDispatchToProps = {
  resendMail: fetchResendVerifyMailActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailLinkInvalidPage)
