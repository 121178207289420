import { ThemeProvider } from '@material-ui/core/styles'

import useAppTheme from '../lib/customHooks/useAppTheme'

const CustomThemeProvider = ({ children }) => {
  const theme = useAppTheme()
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default CustomThemeProvider
