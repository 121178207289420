import { Box, Typography } from '@material-ui/core'
import size from 'lodash/size'
import some from 'lodash/some'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CodeInput, CustomSaveButton, FormLayout } from '..'
import { GOOGLE_TAG_MANAGER } from '../../config'
import useDataLayer from '../../lib/customHooks/useDataLayer'
import { useTranslate } from '../../lib/translate'
import { fetchVerifyPhoneActions } from '../../redux/actions'
import { isBackendVerifyPhoneFetching } from '../../redux/selectors'

const VerifyPhoneForAuthPage = ({ verifyPhone, isFetching }) => {
  const translate = useTranslate()
  const history = useHistory()
  const [values, setValues] = useState({})
  const pushToDataLayer = useDataLayer()

  const onSubmit = () => {
    const code = Object.values(values).join('')
    verifyPhone({
      code,
      history,
      onSuccess: () => pushToDataLayer({ event: GOOGLE_TAG_MANAGER.EVENT.REGISTRATION_COMPLETE }),
    })
  }

  return (
    <FormLayout>
      <Box mb={5}>
        <Typography variant="h6" gutterBottom>
          <Box fontWeight="fontWeightBold" component="span">
            {translate('twoFactorAuthCheck.setupTitle')}
          </Box>
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {translate('twoFactorAuthCheck.subtitle')}
        </Typography>
      </Box>
      <Box mb={5}>
        <CodeInput values={values} setValues={setValues} onSubmit={onSubmit} />
      </Box>
      <Box textAlign="right" mb={5}>
        <CustomSaveButton
          disabled={size(values) < 4 || some(values, val => val === '')}
          loading={isFetching}
          onClick={onSubmit}
        >
          {translate('twoFactorAuthCheck.sendButton')}
        </CustomSaveButton>
      </Box>
    </FormLayout>
  )
}

VerifyPhoneForAuthPage.propTypes = {
  verifyPhone: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
}

const mapStateToProps = state => ({
  isFetching: isBackendVerifyPhoneFetching(state),
})

const mapDispatchToProps = {
  verifyPhone: fetchVerifyPhoneActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhoneForAuthPage)
