import { IconButton, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'

import { useTranslate } from '../../../lib/translate'
import { StyledTableCell } from './styled'

const useStyles = makeStyles(theme => ({
  customHeadShadow: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
    '& th:first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& th:last-child': {
      borderRadius: '0 10px 10px 0',
    },
  },
}))

const CustomTableHead = ({ items, order, orderCol, onSort }) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <TableHead className={classes.customHeadShadow}>
      <TableRow>
        {Array.isArray(items) &&
          items.map((it, i) => (
            <StyledTableCell
              align={i === 0 ? 'left' : 'right'}
              key={it.name}
              sortDirection={orderCol === it.name ? order : false}
            >
              <TableSortLabel
                active={orderCol === it.name}
                direction={orderCol === it.name ? order : 'asc'}
                onClick={() => onSort(it.name)}
              >
                {it.label ? translate(it.label) : null}
              </TableSortLabel>
              {it.dialogControl && (
                <IconButton style={{ marginLeft: 10 }} size="small" onClick={it.dialogControl}>
                  <InfoOutlinedIcon fontSize="small" color="secondary" />
                </IconButton>
              )}
            </StyledTableCell>
          ))}
      </TableRow>
    </TableHead>
  )
}

export default CustomTableHead
