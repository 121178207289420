import { ACTION_PREFIX } from '../globalActions'
import createAction from '../createAction'

/**
 * Main Login/Logout Actions
 */
export const APP_LOGIN = `${ACTION_PREFIX}/APP_LOGIN`
export const appLogin = createAction(APP_LOGIN)

export const APP_LOGIN_SUCCESS = `${ACTION_PREFIX}/APP_LOGIN_SUCCESS`
export const appLoginSuccess = createAction(APP_LOGIN_SUCCESS)

export const APP_LOGOUT = `${ACTION_PREFIX}/APP_LOGOUT`
export const appLogout = createAction(APP_LOGOUT)

/**
 * Snackbar Actions
 */
export const SHOW_NOTIFICATION = `${ACTION_PREFIX}/SHOW_NOTIFICATION`
export const showNotification = createAction(SHOW_NOTIFICATION, 'message', 'type')

export const HIDE_NOTIFICATION = `${ACTION_PREFIX}/HIDE_NOTIFICATION`
export const hideNotification = createAction(HIDE_NOTIFICATION)

export const SET_IS_INITIAL_LOGIN = `${ACTION_PREFIX}/SET_IS_INITIAL_LOGIN`
export const setIsInitialLogin = createAction(SET_IS_INITIAL_LOGIN, 'isInitialLogin')

// remaining gram for physical payout
export const SET_REMAINING_GRAM = `${ACTION_PREFIX}/SET_REMAINING_GRAM`
export const setRemainingGram = createAction(SET_REMAINING_GRAM, 'remainingGram')

export const SET_EXCHANGE_RATE_RANGE = `${ACTION_PREFIX}/SET_EXCHANGE_RATE_RANGE`
export const setExchangeRateRange = createAction(SET_EXCHANGE_RATE_RANGE, 'range')

/**
 * Charts
 */
export const SET_CHART_METAL = `${ACTION_PREFIX}/SET_CHART_METAL`
export const setChartMetal = createAction(SET_CHART_METAL, 'metal')