import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import get from 'lodash/get'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Field, Form } from 'react-final-form'
import { CodeInput, CustomSaveButton, SliderAdapter } from '..'
import { MIN_ADJUSTMENT_DATE } from '../../config'
import { hideTelNumber } from '../../lib/hideData'
import { usePrevious } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'
import { parseDateToStartOfMonth } from '../../lib/util'
import { validateDateToBeAfterDate, validateMin } from '../../lib/validation'
import { fetchAdjustPlanActions, fetchSendSecurityCodeActions } from '../../redux/actions'
import {
  getBackendAdjustPlanError,
  getBackendSendCodeError,
  getBackendUserData,
  isBackendAdjustPlanFetching,
  isBackendSendCodeFetching,
} from '../../redux/selectors'
import { DatePickerAdapter } from './FormFields'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  gridContainer: {
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}))

const ActivatePlanDialog = ({
  open,
  onClose,
  isFetching,
  isFetchingSendCode,
  fetchSendSecurityCode,
  sendCodeSuccess,
  fetchAdjustPlan,
  userData,
  adjustPlanSuccess,
  plan,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [codeValues, setCodeValues] = useState({})
  const [formValues, setFormValues] = useState()

  const wasPreviouslyFetchingCode = usePrevious(isFetchingSendCode)
  const wasPreviouslyFetching = usePrevious(isFetching)
  const minRate = plan?.minRate ?? 50

  const reactivatePlan = () => {
    const securityCode = Object.values(codeValues).join('')
    fetchAdjustPlan({
      translate,
      securityCode,
      changeBankAccount: false,
      savingsPlanId: plan?.id,
      ...formValues,
    })
  }

  const onSubmit = ({ startMonth, newSavingsRate }) => {
    if (!showCodeInput) {
      fetchSendSecurityCode()
      setFormValues({
        newSavingsRate,
        startMonth: moment(startMonth).format('YYYY-MM-DD'),
      })
    } else {
      reactivatePlan()
    }
  }

  useEffect(() => {
    if (!isFetchingSendCode && wasPreviouslyFetchingCode && sendCodeSuccess) {
      setShowCodeInput(true)
    }
  }, [isFetchingSendCode, sendCodeSuccess, wasPreviouslyFetchingCode])

  useEffect(() => {
    if (!isFetching && wasPreviouslyFetching && adjustPlanSuccess) {
      onClose()
    }
  }, [adjustPlanSuccess, isFetching, onClose, wasPreviouslyFetching])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {translate('activatePlanDialog.title')}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography>{translate('activatePlanDialog.subtitle')}</Typography>
        <Box m={3}>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Collapse in={!showCodeInput}>
                  <Grid container spacing={2} className={classes.gridContainer}>
                    <Grid item xs={7}>
                      <Typography>{translate('planAdjustment.start')}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        name="startMonth"
                        component={DatePickerAdapter}
                        disablePast
                        minDate={MIN_ADJUSTMENT_DATE}
                        openTo="month"
                        views={['month', 'year']}
                        defaultValue={MIN_ADJUSTMENT_DATE}
                        validate={validateDateToBeAfterDate(MIN_ADJUSTMENT_DATE)}
                        parse={parseDateToStartOfMonth}
                        className=""
                      />
                    </Grid>
                  </Grid>
                  <Field
                    name="newSavingsRate"
                    component={SliderAdapter}
                    validate={validateMin(minRate)}
                    sliderLabel={translate('planAdjustment.newSavingsRate')}
                    sliderHelper={translate('cancelPlans.minimumSavingRate', { minRate })}
                    format={parseInt}
                    formatOnBlur
                    min={minRate}
                    defaultValue={50}
                    max={1000}
                  />
                </Collapse>
                <Collapse in={showCodeInput} unmountOnExit>
                  <Box mb={3}>
                    <CodeInput
                      values={codeValues}
                      setValues={setCodeValues}
                      onSubmit={reactivatePlan}
                      textComponent={
                        <Typography variant="body1">
                          {translate('planAdjustment.verify2FA', {
                            phone: hideTelNumber(get(userData, 'phone', '')),
                          })}
                        </Typography>
                      }
                    />
                  </Box>
                </Collapse>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    {showCodeInput && (
                      <Button onClick={() => setShowCodeInput(false)}>
                        {translate('actions.back')}
                      </Button>
                    )}
                  </Box>
                  <CustomSaveButton loading={isFetching || isFetchingSendCode}>
                    {translate('actions.submit')}
                  </CustomSaveButton>
                </Box>
              </form>
            )}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  isFetching: isBackendAdjustPlanFetching(state),
  isFetchingSendCode: isBackendSendCodeFetching(state),
  sendCodeSuccess: !getBackendSendCodeError(state),
  userData: getBackendUserData(state),
  adjustPlanSuccess: !getBackendAdjustPlanError(state),
})

const mapDispatchToProps = {
  fetchAdjustPlan: fetchAdjustPlanActions.requestAction,
  fetchSendSecurityCode: fetchSendSecurityCodeActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePlanDialog)
