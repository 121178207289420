import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { CardMedia, Box, makeStyles, Typography } from '@material-ui/core'

import { ROUTES } from '../../config'
import { useTranslate } from '../../lib/translate'
import useAppLogo from '../../lib/customHooks/useAppLogo'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(20),
  },
  logo: {
    width: '20rem',
    marginBottom: theme.spacing(5),
  },
  typography: {
    color: '#b79000',
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const { pathname } = useLocation()
  const [seconds, setSeconds] = useState(10)
  const appLogo = useAppLogo()

  useEffect(() => {
    const interval = setInterval(() => {
      seconds > 0 ? setSeconds(s => s - 1) : clearInterval(interval)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  if (seconds === 0) {
    return <Redirect to={ROUTES.LOGIN} />
  }

  return (
    <Box className={classes.container}>
      <CardMedia component="img" image={appLogo} alt="App Logo" className={classes.logo} />
      <Typography variant="h5" className={classes.typography} paragraph>
        {translate('notFoundPage.info', { path: pathname })}
      </Typography>
      <Typography variant="h5" className={classes.typography}>
        {translate('notFoundPage.redirectIn', { seconds })}
      </Typography>
    </Box>
  )
}

export default NotFoundPage
