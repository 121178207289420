import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getBackendAccountBrandData } from '../../redux/selectors'
import logo from '../../assets/logo/logo-philoro.svg'

const useAppLogo = () => {
  const { logoUrl } = useSelector(getBackendAccountBrandData) || {}

  const appLogo = useMemo(() => logoUrl ?? logo, [logoUrl])

  return appLogo
}

export default useAppLogo
