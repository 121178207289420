import concat from 'lodash/concat'
import drop from 'lodash/drop'

import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../actions'

const initialState = []

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action

  if (type === SHOW_NOTIFICATION) {
    return concat(state, payload)
  } else if (type === HIDE_NOTIFICATION) {
    return drop(state)
  }

  return state
}

export default notificationReducer
