import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  IconButton,
  Popover,
  List,
  ListItem,
  Typography,
  Collapse,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

import { useTranslate } from '../../../lib/translate'
import {
  METAL_TYPE,
  REQUEST_RECORD_TYPE,
  REQUEST_STATUS,
  REQUEST_STATUS_LABEL_MAPPING,
} from '../../../config'

const useStyles = makeStyles(theme => ({
  marginLeft: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120,
  },
  textField: {
    margin: theme.spacing(),
  },
}))

/**
 * The Filter Methods for each filter
 */
const filterByRequestName = (array, value) => filter(array, { type: value })

const filterByRequestVariety = (array, value) => filter(array, itm => itm.variety.includes(value))

const filterByRequestRate = (array, value) => filter(array, { rate: parseInt(value) })

const filterByRequestStatus = (array, value) => filter(array, { status: value })

/**
 * All possible filters
 */
export const REQUEST_TABLE_FILTER = {
  TYPE: {
    name: 'type',
    label: 'table.type',
    filter: filterByRequestName,
  },
  VARIETY: {
    name: 'variety',
    label: 'table.variety',
    filter: filterByRequestVariety,
  },
  RATE: {
    name: 'rate',
    label: 'table.rate',
    filter: filterByRequestRate,
  },
  STATUS: {
    name: 'status',
    label: 'table.status',
    filter: filterByRequestStatus,
  },
}

/**
 * The Filter Menu Selection Popover
 */
export const FilterPopover = ({ anchorEl, closePopover, selectedFilter, addFilter }) => {
  const translate = useTranslate()

  const availableFilter = Object.values(REQUEST_TABLE_FILTER).filter(
    itm => !selectedFilter.includes(itm.name)
  )

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List>
        {!isEmpty(availableFilter) ? (
          availableFilter.map(itm => (
            <ListItem
              button
              onClick={() => {
                availableFilter.length === 1 && closePopover()
                addFilter(itm.name)
              }}
              key={`filter-item-${itm.name}`}
            >
              <Typography>{translate(itm.label)}</Typography>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <Typography>{translate('table.noMoreFilter')}</Typography>
          </ListItem>
        )}
      </List>
    </Popover>
  )
}

/**
 * One Filter item
 * Renders a collapse with remove icon button and the input passed as children
 */
const TableFilterItem = ({ name, selectedFilter, handleRemoveFilter, children }) => {
  return (
    <Collapse in={selectedFilter.includes(name)} unmountOnExit>
      <Grid item xs={12} sm container alignItems="flex-end">
        <Grid item>
          <IconButton edge="end" onClick={() => handleRemoveFilter(name)}>
            <ClearIcon />
          </IconButton>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Collapse>
  )
}

/**
 * Main Request Table Filter Component
 */
const TableFilter = ({ filterStates, selectedFilter, handleRemoveFilter, handleInputChange }) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Grid item xs={12} container spacing={2} className={classes.marginLeft}>
      <TableFilterItem
        name={REQUEST_TABLE_FILTER.TYPE.name}
        selectedFilter={selectedFilter}
        handleRemoveFilter={handleRemoveFilter}
      >
        <FormControl className={classes.formControl}>
          <InputLabel>{translate(REQUEST_TABLE_FILTER.TYPE.label)}</InputLabel>
          <Select
            value={filterStates[REQUEST_TABLE_FILTER.TYPE.name].value}
            onChange={handleInputChange(REQUEST_TABLE_FILTER.TYPE.name)}
          >
            {Object.values(REQUEST_RECORD_TYPE).map(itm => (
              <MenuItem value={itm.label} key={`name-filter-item-${itm.id}`}>
                {translate(itm.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableFilterItem>
      <TableFilterItem
        name={REQUEST_TABLE_FILTER.VARIETY.name}
        selectedFilter={selectedFilter}
        handleRemoveFilter={handleRemoveFilter}
      >
        <FormControl className={classes.formControl}>
          <InputLabel>{translate(REQUEST_TABLE_FILTER.VARIETY.label)}</InputLabel>
          <Select
            value={filterStates[REQUEST_TABLE_FILTER.VARIETY.name].value}
            onChange={handleInputChange(REQUEST_TABLE_FILTER.VARIETY.name)}
          >
            {Object.values(METAL_TYPE).map(itm => (
              <MenuItem value={`metals.${itm}`} key={`variety-filter-item-${itm}`}>
                {translate(`metals.${itm}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableFilterItem>
      <TableFilterItem
        name={REQUEST_TABLE_FILTER.RATE.name}
        selectedFilter={selectedFilter}
        handleRemoveFilter={handleRemoveFilter}
      >
        <TextField
          className={classes.textField}
          label={translate(REQUEST_TABLE_FILTER.RATE.label)}
          onChange={handleInputChange(REQUEST_TABLE_FILTER.RATE.name)}
          type="number"
        />
      </TableFilterItem>
      <TableFilterItem
        name={REQUEST_TABLE_FILTER.STATUS.name}
        selectedFilter={selectedFilter}
        handleRemoveFilter={handleRemoveFilter}
      >
        <FormControl className={classes.formControl}>
          <InputLabel>{translate(REQUEST_TABLE_FILTER.STATUS.label)}</InputLabel>
          <Select
            value={filterStates[REQUEST_TABLE_FILTER.STATUS.name].value}
            onChange={handleInputChange(REQUEST_TABLE_FILTER.STATUS.name)}
          >
            {Object.values(REQUEST_STATUS)
              .filter(it => it !== REQUEST_STATUS.DELETED)
              .map(itm => (
                <MenuItem value={itm} key={`status-filter-item-${itm}`}>
                  {translate(REQUEST_STATUS_LABEL_MAPPING[itm])}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </TableFilterItem>
    </Grid>
  )
}

export default TableFilter
