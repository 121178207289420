import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
  IconButton,
  Box,
  useTheme,
} from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import FilterListIcon from '@material-ui/icons/FilterList'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import GetAppIcon from '@material-ui/icons/GetApp'
import DeleteIcon from '@material-ui/icons/Delete'
import orderBy from 'lodash/orderBy'

import { StyledTableCell, StyledTabs, StyledTab, StyledTableContainer } from './styled'
import { SkeletonTable } from '../..'
import { useTranslate } from '../../../lib/translate'
import {
  REQUEST_STATUS_PENDING,
  REQUEST_STATUS_USER_ACTION_REQUIRED,
  REQUEST_STATUS_LABEL_MAPPING,
  ROUTES,
} from '../../../config'
import useListFilterPopover from '../../../lib/customHooks/useListFilterPopover'
import RequestTableFilter, { FilterPopover, REQUEST_TABLE_FILTER } from './RequestTableFilter'
import {
  fetchCustomFilesActions,
  fetchDeleteRequestActions,
  fetchDownloadFileActions,
} from '../../../redux/actions'
import {
  getBackendCustomFilesData,
  getBackendSavingsPlansData,
  isBackendDeleteRequestFetching,
  isBackendDownloadFileFetching,
} from '../../../redux/selectors'
import LoadingIconButton from '../LoadingIconButton'
import DeleteRequestDialog from '../DeleteRequestDialog'
import useDialog from '../../../lib/customHooks/useDialog'
import useCurrency from '../../../lib/customHooks/useCurrency'
import useIsUnderaged from '../../../lib/customHooks/useIsUnderaged'

const useStyles = makeStyles(theme => ({
  tableRow: {
    '& > *': {
      border: 'unset',
    },
  },
  customHeadShadow: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
    '& th:first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& th:last-child': {
      borderRadius: '0 10px 10px 0',
    },
  },
}))

// Filter functions for the tab selection
const filterByPendingStatus = (array, value) =>
  filter(array, itm => REQUEST_STATUS_PENDING.includes(itm.status))
const filterByNotPendingStatus = (array, value) =>
  filter(array, itm => !REQUEST_STATUS_PENDING.includes(itm.status))

/**
 * Component for displaying a single row in the table
 */
const CustomTableRow = ({ item }) => {
  const theme = useTheme()
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const dispatch = useDispatch()
  const displayCurrency = useCurrency()
  const isUnderaged = useIsUnderaged()

  const [dialogOpen, { openDialog, closeDialog }] = useDialog()
  const customFiles = useSelector(getBackendCustomFilesData)
  const plans = useSelector(getBackendSavingsPlansData)

  const isFetchingDownloadFile = useSelector(isBackendDownloadFileFetching)
  const isBackendDeleteFetching = useSelector(isBackendDeleteRequestFetching)
  const [downloadClicked, setDownloadClicked] = useState(false)
  const [isFetchingFile, setIsFetchingFile] = useState(false)
  const [isFetchingDelete, setIsFetchingDelete] = useState(false)

  const {
    id,
    incrementingId,
    startingDate,
    type,
    rate,
    status,
    variety,
    createdDate,
    savingsPlan,
  } = item || {}

  const fileId = get(find(customFiles, { request: id }), 'id')
  const relatedPlan = find(plans, { id: savingsPlan })

  const actionRequired = REQUEST_STATUS_USER_ACTION_REQUIRED.includes(status)
  const statusPending = REQUEST_STATUS_PENDING.includes(status)

  const onCompleteRequestClick = () => {
    history.push(`${ROUTES.COMPLETE_REQUEST}/${id}`)
  }

  const onDownload = () => {
    dispatch(
      fetchDownloadFileActions.requestAction({ id: fileId, name: translate('fileName.request') })
    )
    setDownloadClicked(true)
  }

  const onDelete = () => {
    setIsFetchingDelete(true)
    closeDialog()
    dispatch(fetchDeleteRequestActions.requestAction({ id }))
  }

  useEffect(() => {
    if (isFetchingDownloadFile && downloadClicked) {
      setIsFetchingFile(true)
    }
  }, [downloadClicked, isFetchingDownloadFile])

  useEffect(() => {
    if (!isFetchingDownloadFile) {
      setIsFetchingFile(false)
      setDownloadClicked(false)
    }
  }, [isFetchingDownloadFile])

  useEffect(() => {
    if (!isBackendDeleteFetching) {
      setIsFetchingDelete(false)
    }
  }, [isBackendDeleteFetching])

  return (
    <>
      <TableRow className={classes.tableRow}>
        <StyledTableCell>{incrementingId}</StyledTableCell>
        <StyledTableCell align="right">
          {relatedPlan?.customName || relatedPlan?.name}
        </StyledTableCell>
        <StyledTableCell align="right">
          {moment(createdDate).format(translate('formFields.dateFormat'))}
        </StyledTableCell>
        <StyledTableCell align="right">{type ? translate(type) : ''}</StyledTableCell>
        <StyledTableCell align="right">{variety.map(v => translate(v)).join(', ')}</StyledTableCell>
        <StyledTableCell align="right">{displayCurrency(rate)}</StyledTableCell>
        <StyledTableCell align="right">
          {moment(startingDate).format(translate('formFields.dateFormat'))}
        </StyledTableCell>
        <StyledTableCell align="right">
          {translate(REQUEST_STATUS_LABEL_MAPPING[status])}
        </StyledTableCell>
        <StyledTableCell align="right">
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {actionRequired ? (
              <LoadingIconButton
                tooltip={translate('table.continueTooltip')}
                icon={<PlayArrowIcon />}
                small
                onClick={onCompleteRequestClick}
              />
            ) : (
              <LoadingIconButton
                tooltip={translate('table.downloadTooltip')}
                icon={<GetAppIcon />}
                small
                onClick={onDownload}
                disabled={!fileId}
                loading={isFetchingFile}
              />
            )}
            <LoadingIconButton
              icon={<DeleteIcon />}
              tooltip={translate('table.deleteTooltip')}
              small
              onClick={openDialog}
              disabled={!statusPending || isUnderaged}
              loading={isFetchingDelete}
              color={theme.palette.error.main}
            />
          </Box>
        </StyledTableCell>
      </TableRow>
      <DeleteRequestDialog open={dialogOpen} onClose={closeDialog} onSubmit={onDelete} />
    </>
  )
}

/**
 * Main Request Table Component
 *
 * Since filtering is done in the frontend for now, it's a bit more complicated than necessary
 * TODO: Move Filtering and Pagination handling to backend
 */
const RequestsTable = ({ data, loading }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()

  const [tableData, setTableData] = useState(data)
  const [filters, setFilters] = useState(
    Object.values(REQUEST_TABLE_FILTER).reduce(
      // eslint-disable-next-line no-sequences
      (obj, val) => ((obj[val.name] = { value: '', filter: val.filter }), obj),
      {}
    )
  )
  const [tabValue, setTabValue] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [{ anchorEl, selectedFilter }, { openPopover, closePopover, addFilter, removeFilter }] =
    useListFilterPopover()

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    if (newValue === 0) {
      setFilters(old => ({ ...old, tab: {} }))
    } else if (newValue === 1) {
      setFilters(old => ({
        ...old,
        tab: { value: 1, filter: filterByPendingStatus },
      }))
    } else if (newValue === 2) {
      setFilters(old => ({
        ...old,
        tab: { value: 1, filter: filterByNotPendingStatus },
      }))
    }
  }

  const handleRemoveFilter = name => {
    removeFilter(name)
    setFilters(old => ({ ...old, [name]: { ...old[name], value: '' } }))
  }

  const handleFilterInputChange = name => event => {
    setFilters(old => ({
      ...old,
      [name]: {
        ...old[name],
        value: event.target.value,
      },
    }))
  }

  useEffect(() => {
    setPage(0)
    setTableData(
      Object.entries(filters).reduce(
        (out, [k, v]) => (!!v.value ? v.filter(out, v.value) : out),
        data
      )
    )
  }, [data, filters, setTableData])

  useEffect(() => {
    dispatch(fetchCustomFilesActions.requestAction())
  }, [dispatch])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <StyledTabs value={tabValue} onChange={handleTabChange}>
            <StyledTab label={translate('table.tabAll')} />
            <StyledTab label={translate('table.tabPending')} />
            <StyledTab label={translate('table.tabCompleted')} />
          </StyledTabs>
          <IconButton onClick={openPopover}>
            <FilterListIcon />
          </IconButton>
          <FilterPopover
            anchorEl={anchorEl}
            closePopover={closePopover}
            selectedFilter={selectedFilter}
            addFilter={addFilter}
          />
        </Box>
      </Grid>
      <RequestTableFilter
        filterStates={filters}
        selectedFilter={selectedFilter}
        handleRemoveFilter={handleRemoveFilter}
        handleInputChange={handleFilterInputChange}
      />
      <Grid item xs={12}>
        {loading ? (
          <SkeletonTable />
        ) : (
          <>
            <StyledTableContainer>
              <Table>
                <TableHead className={classes.customHeadShadow}>
                  <TableRow>
                    <StyledTableCell>{translate('table.contract')}</StyledTableCell>
                    <StyledTableCell align="right">
                      {translate('table.savingsPlan')}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {translate('table.creationDate')}
                    </StyledTableCell>
                    <StyledTableCell align="right">{translate('table.type')}</StyledTableCell>
                    <StyledTableCell align="right">{translate('table.variety')}</StyledTableCell>
                    <StyledTableCell align="right">{translate('table.rate')}</StyledTableCell>
                    <StyledTableCell align="right">{translate('table.fromDate')}</StyledTableCell>
                    <StyledTableCell align="right">{translate('table.status')}</StyledTableCell>
                    <StyledTableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(tableData) &&
                    orderBy(tableData, ['createdDate'], ['desc'])
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, i) => <CustomTableRow key={`table-row-${i}`} item={item} />)}
                </TableBody>
              </Table>
            </StyledTableContainer>
            {tableData && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={tableData?.length || 0}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={translate('table.rowsPerPage')}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from} - ${to} ${translate('table.of')} ${count}`
                }
                page={page}
                onChangePage={(event, newPage) => setPage(newPage)}
                onChangeRowsPerPage={event => {
                  setRowsPerPage(event.target.value)
                  setPage(0)
                }}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default RequestsTable
