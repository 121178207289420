import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Card, CardContent, Link } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, Form } from 'react-final-form'

import { useTranslate } from '../../lib/translate'
import { TextFieldAdapter } from '../common/FormFields'
import { required } from '../../lib/validation'
import CustomSaveButton from '../common/CustomSaveButton'
import { fetchMigrateActions } from '../../redux/actions'
import {
  isBackendMigrateFetching,
  getBackendCountryDepartmentData,
  getBackendUserData,
} from '../../redux/selectors'
import useQueryParams from '../../lib/customHooks/useQueryParams'
import { ROUTES } from '../../config'
import { CheckboxAdapter } from '..'

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  cardContentPadding: {
    padding: theme.spacing(3.75),
  },
  centerCard: {
    display: 'flex',
    minHeight: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    minWidth: '30rem',
    maxWidth: '44rem',
  },
  loadingText: {
    marginTop: '1rem',
  },
  marginBottomBox: {
    marginBottom: '1.25rem',
  },
}))

const AlreadyMigratedContent = ({ classes, translate }) => (
  <Box className={classes.centerCard}>
    <Card className={classes.card}>
      <CardContent className={classes.cardContentPadding}>
        {translate('migrationPage.alreadyMigrated')}
      </CardContent>
    </Card>
  </Box>
)

const MigrationPage = ({ migrate, isFetching, countryDepartment, userData }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const queryParams = useQueryParams()
  const history = useHistory()
  const [acceptedBGB, setAcceptedBGB] = useState(false)

  const onSubmit = values => {
    migrate({ values, newRoute: ROUTES.DASHBOARD, history })
  }

  if (userData?.finishedMigration) {
    return <AlreadyMigratedContent classes={classes} translate={translate} />
  }

  return (
    <Box className={classes.centerCard}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContentPadding}>
          <Typography variant="h6" paragraph className={classes.boldText}>
            {translate('migrationPage.title')}
          </Typography>
          <Typography variant="body1" paragraph>
            {translate('migrationPage.info')}
          </Typography>
          <Typography variant="h5" paragraph className={classes.boldText}>
            {translate('migrationPage.title2')}
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: translate('migrationPage.list1'),
                }}
              />
            </li>
            <li>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: translate('migrationPage.list2'),
                }}
              />
            </li>
            <li>
              <Typography variant="body1">{translate('migrationPage.list3')}</Typography>
            </li>
            <li>
              <Typography variant="body1">{translate('migrationPage.list4')}</Typography>
            </li>
            <li>
              <Typography variant="body1">{translate('migrationPage.list5')}</Typography>
            </li>
            <li>
              <Typography variant="body1">{translate('migrationPage.list6')}</Typography>
            </li>
            <li>
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: translate('migrationPage.list7'),
                }}
              />
            </li>
            <li>
              <Typography variant="body1">{translate('migrationPage.list8')}</Typography>
            </li>
          </ul>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box className={classes.marginBottomBox}>
                  <Field
                    name="acceptBGB"
                    component={CheckboxAdapter}
                    onChange={setAcceptedBGB}
                    label={
                      <>
                        {translate('migrationPage.acceptBgbStart') + ' '}
                        <Link
                          href={`${ROUTES.BGB}?country=${countryDepartment?.countryCode}`}
                          target="_blank"
                        >
                          {translate('formFields.checkboxes.bgb')}
                        </Link>
                        {' ' + translate('migrationPage.acceptBgbEnd')}
                      </>
                    }
                  />
                </Box>
                <Box className={classes.marginBottomBox}>
                  <Typography variant="body1" paragraph>
                    {translate('migrationPage.info2')}
                  </Typography>
                </Box>
                <Field
                  name="uuid"
                  component={TextFieldAdapter}
                  validate={required}
                  fullWidth
                  label={translate('formFields.uuid')}
                  initialValue={queryParams.get('uuid')}
                />
                <Box className={classes.marginBottomBox}>
                  <Typography variant="body1" paragraph>
                    {translate('migrationPage.info3')}
                  </Typography>
                </Box>
                <CustomSaveButton loading={isFetching} disabled={!acceptedBGB}>
                  {translate('migrationPage.button')}
                </CustomSaveButton>
                {isFetching && (
                  <Typography variant="body1" paragraph className={classes.loadingText}>
                    {translate('migrationPage.loadingText')}
                  </Typography>
                )}
              </form>
            )}
          />
        </CardContent>
      </Card>
    </Box>
  )
}

const mapStateToProps = state => ({
  isFetching: isBackendMigrateFetching(state),
  countryDepartment: getBackendCountryDepartmentData(state),
  userData: getBackendUserData(state),
})

const mapDispatchToProps = {
  migrate: fetchMigrateActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(MigrationPage)
