import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getBackendUserData } from '../../redux/selectors'

const useIsUnderaged = () => {
  const { dateOfBirth } = useSelector(getBackendUserData) || {}

  const isUnderaged = useMemo(() => {
    if (!dateOfBirth) {
      return false
    }
    const ageYears = moment
      .duration(moment().startOf('day').diff(moment(dateOfBirth).startOf('day')))
      .asYears()
    return ageYears < 18
  }, [dateOfBirth])

  return isUnderaged
}

export default useIsUnderaged
