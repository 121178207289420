import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker } from '@material-ui/pickers'
import find from 'lodash/find'
import get from 'lodash/get'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ProductInfoDialog } from '../'
import { COUNTRIES_TO_DISPLAY_FIRST } from '../../config'
import useCurrency from '../../lib/customHooks/useCurrency'
import useDialog from '../../lib/customHooks/useDialog'
import { countryToFlag } from '../../lib/miscellaneous'
import { useTranslate } from '../../lib/translate'
import { getBackendProductsData } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  checkBox: {
    color: props =>
      props.touched && props.invalid ? theme.palette.error.main : theme.palette.primary.main,
  },
  option: {
    fontSize: theme.typography.overline.fontSize,
    '& > span': {
      marginRight: theme.spacing(0.5),
      fontSize: theme.typography.overline.fontSize,
    },
  },
  dialingCodeInput: {
    padding: 0,
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  sliderThumb: {
    color: theme.palette.primary.main,
  },
  textField: {
    marginBottom: '1.25rem',
  },
  locationIcon: {
    marginRight: theme.spacing(2),
  },
}))

export const TextFieldAdapter = ({ input, meta, ...rest }) => {
  const translate = useTranslate()
  const classes = useStyles()

  // Sometimes we want to display multiple different error messages, then we pass them as an array of strings.
  const error = useMemo(() => {
    if (!meta.error) return ''
    if (Array.isArray(meta.error)) return meta.error.map(e => translate(e)).join(', ')
    return translate(meta.error)
  }, [meta.error, translate])

  return (
    <TextField
      className={classes.textField}
      onChange={event => input.onChange(event.target.value)}
      helperText={meta.touched ? error : ''}
      error={meta.touched ? meta.invalid : false}
      variant="outlined"
      {...input}
      {...rest}
    />
  )
}

export const CheckboxAdapter = ({ input, meta, onChange, ...rest }) => {
  const classes = useStyles(meta)
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            {...rest}
            checked={input.value || false}
            onChange={event => {
              typeof onChange === 'function' && onChange(event.target.checked)
              input.onChange(event.target.checked)
            }}
            className={classes.checkBox}
            color="primary"
          />
        }
        {...input}
        {...rest}
      />
    </FormControl>
  )
}

export const SelectAdapter = ({ input, meta, label, helperText, children, ...rest }) => (
  <FormControl
    variant="outlined"
    error={meta.touched ? meta.invalid : false}
    style={{ marginBottom: '1.25rem' }}
    {...rest}
  >
    <InputLabel>{label}</InputLabel>
    <Select {...input} onChange={event => input.onChange(event.target.value)} label={label}>
      {children}
    </Select>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
)

export const DatePickerAdapter = ({ input, meta, label, ...rest }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const error = meta.error ? translate(meta.error) : ''

  return (
    <KeyboardDatePicker
      {...input}
      value={input.value || null}
      autoOk
      invalidDateMessage={meta.touched ? translate('validation.invalidDateFormat') : ''}
      maxDateMessage={translate('validation.notAfterMaxDate')}
      minDateMessage={translate('validation.notBeforeMinDate')}
      className={classes.textField}
      inputVariant="outlined"
      label={label}
      format={translate('formFields.dateFormat')}
      InputAdornmentProps={{ position: 'end' }}
      onChange={date => input.onChange(date)}
      error={meta.touched ? meta.invalid : false}
      helperText={meta.touched ? error : ''}
      InputLabelProps={{ shrink: !!input.value || undefined }}
      {...rest}
    />
  )
}

/**
 * see https://material-ui.com/components/autocomplete/#country-select
 * and https://create-react-app.dev/docs/code-splitting/
 */
export const DialingCodeAdaper = ({ input, meta, ...rest }) => {
  const [options, setOptions] = useState([])
  const classes = useStyles()
  const translate = useTranslate()
  const error = meta.error ? translate(meta.error) : ''

  useEffect(() => {
    let active = true

    import('../../config/countries')
      .then(({ countries }) => {
        if (active) {
          const sortedCountries = countries.reduce(
            (array, it) =>
              COUNTRIES_TO_DISPLAY_FIRST.includes(it?.code) ? [it, ...array] : [...array, it],
            []
          )
          setOptions(sortedCountries)
        }
      })
      .catch(error => {
        console.warn('Error while trying to import countries: ', error)
      })
    return () => {
      active = false
    }
  }, [])

  return (
    <Autocomplete
      {...input}
      {...rest}
      value={input.value || null}
      options={options}
      classes={{
        option: classes.option,
      }}
      disableClearable
      autoSelect
      forcePopupIcon={false}
      onChange={(event, value) => input.onChange(value)}
      fullWidth
      autoHighlight
      getOptionLabel={option => get(option, 'dial_code', '')}
      renderOption={option => (
        <>
          <span>{countryToFlag(get(option, 'code'))}</span>
          {get(option, 'dial_code', '')}
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField}
          helperText={meta.touched ? error : ''}
          error={meta.touched ? meta.invalid : false}
          label={translate('formFields.dialCode')}
          variant="outlined"
        />
      )}
    />
  )
}

export const SliderAdapter = ({
  input,
  meta,
  sliderLabel,
  sliderHelper,
  min,
  max,
  removeSlider,
  defaultValue,
  ...rest
}) => {
  const translate = useTranslate()
  const displayCurrency = useCurrency()
  const classes = useStyles()
  const error = meta.error ? translate(meta.error) : ''

  return (
    <Grid container spacing={2}>
      <Grid item xs={7} container alignItems="center">
        <Typography gutterBottom variant="body1">
          {sliderLabel}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <TextField
          {...input}
          {...rest}
          className={classes.textField}
          helperText={meta.touched ? error : ''}
          error={meta.touched ? meta.invalid : false}
          variant="outlined"
          type="number"
          onChange={event => input.onChange(event.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">{displayCurrency()}</InputAdornment>,
          }}
        />
      </Grid>
      {!removeSlider && (
        <Grid item xs={12}>
          <Slider
            value={parseFloat(input.value)}
            classes={{
              thumb: classes.sliderThumb,
            }}
            onChange={(event, value) => input.onChange(value)}
            color="secondary"
            min={min}
            max={max}
            defaultValue={defaultValue || min}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" paragraph className={classes.greyText}>
          {sliderHelper}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const AutocompleteAdapter = ({ input, meta, options, translateLabel = true, ...rest }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const error = meta.error ? translate(meta.error) : ''

  return (
    <Autocomplete
      {...input}
      {...rest}
      value={input.value || null}
      options={options}
      getOptionSelected={(opt, val) => opt.label === val.label}
      onChange={(event, value) => input.onChange(value)}
      autoHighlight
      disableClearable
      getOptionLabel={option =>
        translateLabel ? translate(get(option, 'label', '')) : get(option, 'label', '')
      }
      renderInput={params => (
        <TextField
          {...rest}
          {...params}
          className={classes.textField}
          helperText={meta.touched ? error : ''}
          error={meta.touched ? meta.invalid : false}
          variant="outlined"
        />
      )}
    />
  )
}

export const ProductAdapter = ({ input, meta, children, handleOpenDialog, ...rest }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [dialogState, { openDialog, closeDialog }] = useDialog()

  const products = useSelector(getBackendProductsData)

  const error = meta.error ? translate(meta.error) : ''

  const handleChange = isSelect => value => {
    const oldInputValue = input.value || {}
    if (isSelect) {
      input.onChange({ ...oldInputValue, id: value })
    } else {
      input.onChange({ ...oldInputValue, qty: value ? parseInt(value) : '' })
    }
  }
  return (
    <Grid container direction="row" justify="space-between" spacing={2}>
      <Grid item xs={1} container justify="center" alignItems="center">
        <Grow in={!!input?.value?.id}>
          <IconButton onClick={openDialog} size="small">
            <InfoOutlinedIcon color="secondary" fontSize="small" />
          </IconButton>
        </Grow>
      </Grid>
      <Grid item xs={8}>
        <FormControl
          variant="outlined"
          error={meta.touched ? meta.invalid : false}
          fullWidth
          {...rest}
        >
          <InputLabel>{translate('cancelPlans.productName')}</InputLabel>
          <Select
            value={get(input, 'value.id', '')}
            fullWidth
            onChange={event => handleChange(true)(event.target.value)}
            label={translate('cancelPlans.productName')}
          >
            {children}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <TextField
          {...rest}
          className={classes.textField}
          type="number"
          style={{ marginBottom: 0 }}
          inputProps={{ min: 0 }}
          value={get(input, 'value.qty', '')}
          label={translate('cancelPlans.piece')}
          onChange={event => handleChange(false)(event.target.value)}
          helperText={meta.touched ? error : ''}
          error={meta.touched ? meta.invalid : false}
          variant="outlined"
        />
      </Grid>
      <ProductInfoDialog
        open={dialogState}
        onClose={closeDialog}
        product={find(products, { id: input?.value?.id })}
      />
    </Grid>
  )
}

export const NationalitiesAdapter = ({ input, meta, isCountryPicker, ...rest }) => {
  const [options, setOptions] = useState([])
  const classes = useStyles()
  const translate = useTranslate()
  const error = meta.error ? translate(meta.error) : ''

  useEffect(() => {
    let active = true

    import('../../config/countries')
      .then(({ countries }) => {
        if (active) {
          const sortedCountries = countries.reduce(
            (array, it) =>
              COUNTRIES_TO_DISPLAY_FIRST.includes(it?.code) ? [it, ...array] : [...array, it],
            []
          )
          setOptions(sortedCountries)
        }
      })
      .catch(error => {
        console.warn('Error while trying to import countries: ', error)
      })
    return () => {
      active = false
    }
  }, [])

  useEffect(() => {
    if (input.value && typeof input.value !== 'object' && options.length) {
      input.onChange(find(options, { code: input.value }))
    }
  }, [input, options])

  return (
    <Autocomplete
      {...input}
      {...rest}
      value={typeof input.value === 'object' ? input.value : null}
      options={options}
      classes={{
        option: classes.option,
        listbox: classes.listBox,
      }}
      onChange={(event, value) => input.onChange(value)}
      fullWidth
      autoHighlight
      autoSelect
      getOptionLabel={option =>
        isCountryPicker ? get(option, 'name_de', '') : get(option, 'nationality_de', '')
      }
      renderOption={option => (
        <>
          <span>{countryToFlag(get(option, 'code'))}</span>
          {isCountryPicker ? get(option, 'name_de', '') : get(option, 'nationality_de', '')}
        </>
      )}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textField}
          helperText={meta.touched ? error : ''}
          error={meta.touched ? meta.invalid : false}
          label={
            isCountryPicker ? translate('formFields.country') : translate('formFields.nationality')
          }
          variant="outlined"
        />
      )}
    />
  )
}

export const RadioAdapter = ({ input, meta, children, ...rest }) => {
  const translate = useTranslate()
  return (
    <FormControl
      error={meta.touched ? meta.invalid : false}
      style={{ marginBottom: '1.25rem' }}
      {...rest}
    >
      <RadioGroup {...input} onChange={event => input.onChange(event.target.value)}>
        {children}
      </RadioGroup>
      {meta.touched && meta.invalid && (
        <FormHelperText error>{translate(meta.error)}</FormHelperText>
      )}
    </FormControl>
  )
}
