import React from 'react'
import { Switch } from 'react-router-dom'

import {
  MainLayout,
  UserProfilePage,
  DashboardPage,
  SavingsPlansOverviewPage,
  SavingsPlanDetailsPage,
  CancelPlanPage,
  PaymentsPage,
  SecurityPage,
  RequestsPage,
  CustomRoute,
} from '..'
import { ROUTES } from '../../config'
import { useTranslate } from '../../lib/translate'
import MigrationPage from '../page/MigrationPage'

/**
 * Protected Home Navigation
 *
 * add here all signed-in routes which have the drawer on the side
 *
 * SavingsPlans should always be present, so fetch them here for all pages
 */
const NestedHomeNavigation = () => {
  const translate = useTranslate()

  return (
    <MainLayout>
      <Switch>
        <CustomRoute path={ROUTES.DASHBOARD} title={translate('documentTitle.dashboard')}>
          <DashboardPage />
        </CustomRoute>
        <CustomRoute
          path={ROUTES.SAVINGSPLANS_OVERVIEW}
          title={translate('documentTitle.overview')}
        >
          <SavingsPlansOverviewPage />
        </CustomRoute>
        <CustomRoute
          path={`${ROUTES.PLAN_DETAILS}/:id`}
          title={translate('documentTitle.planDetails')}
        >
          <SavingsPlanDetailsPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.PAYMENTS} title={translate('documentTitle.payments')}>
          <PaymentsPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.CANCEL_SAVINGS_PLAN} title={translate('documentTitle.service')}>
          <CancelPlanPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.MIGRATION} title={translate('documentTitle.migration')}>
          <MigrationPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.PROFILE} title={translate('documentTitle.profile')}>
          <UserProfilePage />
        </CustomRoute>
        <CustomRoute path={ROUTES.SECURITY} title={translate('documentTitle.security')}>
          <SecurityPage />
        </CustomRoute>
        <CustomRoute path={ROUTES.REQUESTS} title={translate('documentTitle.requests')}>
          <RequestsPage />
        </CustomRoute>
      </Switch>
    </MainLayout>
  )
}

export default NestedHomeNavigation
