import { useState, useMemo } from 'react'

const useDialog = (initialOpen = false) => {
  const [state, setState] = useState(initialOpen)

  const handlers = useMemo(
    () => ({
      openDialog: () => {
        setState(true)
      },
      closeDialog: () => {
        setState(false)
      },
      toggleDialog: () => {
        setState(s => !s)
      },
    }),
    []
  )

  return [state, handlers]
}

export default useDialog
