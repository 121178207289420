import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
}))

const InfoDialog = ({ open, onClose, title, infoText }) => {
  const classes = useStyles()

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {title}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {typeof infoText === 'string' ? (
          <Typography align="justify">{infoText}</Typography>
        ) : (
          infoText
        )}
      </DialogContent>
    </Dialog>
  )
}

export default InfoDialog
