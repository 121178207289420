import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { SALUTATION_OPTIONS } from '../../config'
import { useTranslate } from '../../lib/translate'
import { appLogout } from '../../redux/actions'
import { getBackendUserData } from '../../redux/selectors'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
}))

const salutationOptions = Object.values(SALUTATION_OPTIONS)

const MaintenancePage = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const userData = useSelector(getBackendUserData)

  const onLogout = () => {
    dispatch(appLogout())
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography variant="h4" color="secondary" align="center">
              Wartungsarbeiten
            </Typography>
          }
        />
        <CardContent>
          <Typography paragraph>
            {`Guten Tag ${
              translate(salutationOptions.find(item => item.value === userData.salutation)?.key) ||
              ''
            } ${userData.lastName},`}
          </Typography>
          <Typography>
            aufgrund von geplanten Wartungsarbeiten ist das Kundenportal kurzzeitig nicht verfügbar.
          </Typography>
          <Typography paragraph>Wir bitten den Umstand zu entschuldigen.</Typography>
          <Typography>Vielen Dank für Ihr Verständnis!</Typography>
        </CardContent>
      </Card>
      <Box className={classes.buttonContainer}>
        <Button onClick={onLogout}>Logout</Button>
      </Box>
    </Container>
  )
}

export default MaintenancePage
