import React from 'react'
import { IconButton, Popover, List, ListItem, Typography } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useDispatch } from 'react-redux'

import { useTranslate } from '../../lib/translate'
import { EXCHANGE_RATE_RANGES } from '../../config'
import usePopover from '../../lib/customHooks/usePopover'
import { fetchExchangeRatesActions } from '../../redux/actions'

const SelectRatesRangePopover = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()

  const [anchorEl, { openPopover, closePopover }] = usePopover()

  const handleFetchRates = range => {
    dispatch(fetchExchangeRatesActions.requestAction({ range }))
    closePopover()
  }

  return (
    <>
      <IconButton size="small" onClick={openPopover}>
        <ArrowDropDownIcon />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {Object.values(EXCHANGE_RATE_RANGES).map(itm => (
            <ListItem button onClick={() => handleFetchRates(itm)} key={`range-option-${itm}`}>
              <Typography>{translate(`chart.${itm}`)}</Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}

export default SelectRatesRangePopover
