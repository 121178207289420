import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Card, CardHeader, CardContent } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 10,
    height: 165,
    width: '100%',
    maxWidth: '28rem',
  },
  borderRadius: {
    borderRadius: 10,
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
}))

export const SkeletonCard = () => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Skeleton variant="circle" width={45} height={45} />}
        title={
          <Typography variant="h6">
            <Skeleton width="25%" />
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body2">
          <Skeleton width="35%" />
        </Typography>
        <Typography variant="h4">
          <Skeleton />
        </Typography>
      </CardContent>
    </Card>
  )
}

export const SkeletonDepotOverview = () => (
  <Grid item xs={12} container spacing={1}>
    <Grid item xs={6} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3">
          <Skeleton width="70%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          <Skeleton width="40%" />
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={6} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3">
          <Skeleton width="70%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          <Skeleton width="40%" />
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)

export const SkeletonTable = () => {
  const classes = useStyles()
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rect" width="100%" height={50} className={classes.borderRadius} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width="100%" height={50} className={classes.borderRadius} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width="100%" height={50} className={classes.borderRadius} />
      </Grid>
    </Grid>
  )
}

export const SkeletonDashboardPercentageChange = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="body2" className={classes.marginLeft}>
        <Skeleton width={100} />
      </Typography>
      <Typography variant="body2" className={classes.marginLeft}>
        <Skeleton width={100} />
      </Typography>
    </>
  )
}

export const SkeletonPlanDetailsOverview = () => (
  <Grid item xs={12} container spacing={1}>
    <Grid item xs={6} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">
          <Skeleton width="70%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" paragraph>
          <Skeleton width="40%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          <Skeleton width="70%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" paragraph>
          <Skeleton width="40%" />
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={6} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h4">
          <Skeleton width="70%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" paragraph>
          <Skeleton width="40%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          <Skeleton width="70%" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" paragraph>
          <Skeleton width="40%" />
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)
