import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { APP_LOGOUT } from './app/actions'

/**
 * This function creates a reducer for a specific action.
 *
 * The first parameter is the action type. When the created reducer gets called, it will only run on actions with this type.
 * The second parameter is callback function, which gets the action payload and should return all changes to the state
 *
 * @function createReducer
 * @param actionType {string} - The type of the action which will trigger the reducer
 * @param reducer {function} - A function which receives the payload of the action and returns an object with all changes to the state. The returned object will be recursively merged with the old state.
 * @param [override] - If false, the reducers result will be merged with the old state. If true, the reducers result will override the entire old state
 * @returns reducer - The created reducer
 */
export const createReducer = (actionType, reducer, override = false) => {
  if (override) {
    return (state, action) => {
      if (actionType !== action.type) return state
      else return reducer(action.payload)
    }
  } else {
    return (state, action) => {
      if (actionType !== action.type) return state
      else return merge(cloneDeep(state), reducer(action.payload))
    }
  }
}

/**
 * This function gets a list of reducers. When it's called, all the reducers will be recursively called.
 *
 * Example:
 *   const combinedReducers = mergeReducers([reducer1, reducer2, reducer3], {foo: "bar"})
 *       ==> combinedReducers equals (state, action) => reducer3(reducer2(reducer1(state || initialState), action), action), action)
 *
 * @function mergeReducers
 * @param reducers - A list of redux reducers
 * @param [initialState] - The initial redux state
 * @returns reducer - All reducers merged
 */
export const mergeReducers = (reducers = [], initialState) =>
  reducers.reduce(
    (acc, reducer) => (s, a) => reducer(acc(s, a), a),
    (state = initialState || {}) => state
  )

/**
 * All backend reducers share the same initial state template
 */
const initialBackendState = {
  isFetching: false,
  timeFetched: null,
  timeFetchedSuccess: null,
  error: null,
  data: null,
}
/**
 * For our backend reducer, we take it even further, cause the structure is always the same
 * => You only need to call this function once to handle REQUEST/SUCCESS/FAILURE for each action
 *
 * @function createBackendReducer
 * @param {object} backendAction result of createBackendActions (see createAction.js)
 * @returns reducer - A backend reducer
 */
export const createBackendReducer =
  backendActions =>
  (state = initialBackendState, action) => {
    const { type, payload, timeFetched } = action

    if (type === APP_LOGOUT || type === backendActions?.flushType) {
      return initialBackendState
    } else if (type === backendActions?.requestType) {
      return {
        ...state,
        isFetching: true,
      }
    } else if (type === backendActions?.successType) {
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
        timeFetched,
        timeFetchedSuccess: timeFetched,
      }
    } else if (type === backendActions?.failureType) {
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
        timeFetched,
      }
    }
    return state
  }

/**
 * Same as above, but stores the values in a nested object with the passed id as key
 */
export const createBackendReducerNestedById =
  backendActions =>
  (state = {}, action) => {
    const { type, payload: { id, ...rest } = {}, timeFetched } = action || {}

    if (type === APP_LOGOUT) {
      return {}
    } else if (type === backendActions?.requestType) {
      return {
        ...state,
        [id]: {
          ...state[id],
          isFetching: true,
        },
      }
    } else if (type === backendActions?.successType) {
      return {
        ...state,
        [id]: {
          ...state[id],
          isFetching: false,
          data: rest,
          error: null,
          timeFetched,
          timeFetchedSuccess: timeFetched,
        },
      }
    } else if (type === backendActions?.failureType) {
      return {
        ...state,
        [id]: {
          ...state[id],
          isFetching: false,
          error: rest,
          timeFetched,
        },
      }
    } else if (type === backendActions?.flushType) {
      if (id) {
        const copyState = { ...state }
        delete copyState[id]
        return copyState
      } else {
        return {}
      }
    }

    return state
  }

export default createReducer
