import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'

import {
  CustomRoute,
  PlanAdjustmentPageOne,
  PlanAdjustmentPageThree,
  PlanAdjustmentPageTwo,
} from '../..'
import { ROUTES } from '../../../config'
import { getAvailablePlans } from '../../../lib/util'
import { storePlanAdjustmentValues } from '../../../redux/actions'
import { getBackendSavingsPlansData, getFormPlanAdjustmentData } from '../../../redux/selectors'
import { WORKFLOW_PAGE_1 } from '../../page/planAdjustment/PlanAdjustmentPage1'

/**
 * Plan Adjustment Navigation
 *
 * Our nested navigation component for the adjust savingsplan workflow
 */
const PlanAdjustmentNavigation = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const formData = useSelector(getFormPlanAdjustmentData)
  const plans = useSelector(getBackendSavingsPlansData)
  const availablePlans = useMemo(() => getAvailablePlans(plans), [plans])

  useEffect(() => {
    if (ROUTES.PLAN_ADJUSTMENT_STEP_ONE === pathname && availablePlans.length === 1) {
      dispatch(storePlanAdjustmentValues(WORKFLOW_PAGE_1)({ savingsPlanId: availablePlans[0].id }))
      history.push(ROUTES.PLAN_ADJUSTMENT_STEP_TWO)
    }
  }, [availablePlans, dispatch, history, pathname])

  if (ROUTES.PLAN_ADJUSTMENT_STEP_ONE !== pathname && isEmpty(formData)) {
    return <Redirect to={ROUTES.PLAN_ADJUSTMENT_STEP_ONE} />
  }

  return (
    <Switch>
      <CustomRoute path={ROUTES.PLAN_ADJUSTMENT_STEP_ONE}>
        <PlanAdjustmentPageOne />
      </CustomRoute>
      <CustomRoute path={ROUTES.PLAN_ADJUSTMENT_STEP_TWO}>
        <PlanAdjustmentPageTwo />
      </CustomRoute>
      <CustomRoute path={ROUTES.PLAN_ADJUSTMENT_STEP_THREE}>
        <PlanAdjustmentPageThree />
      </CustomRoute>
    </Switch>
  )
}

export default PlanAdjustmentNavigation
