import { Box, Divider, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  lightText: {
    color: theme.palette.text.secondary,
  },
  divider: {
    backgroundColor: theme.palette.text.disabled,
  },
  text: {
    fontSize: 12,
    lineHeight: 1.25,
    letterSpacing: 1.5,
    fontWeight: ({ bold }) => (bold ? 700 : 400),
  },
  helperText: {
    marginTop: 4,
    fontSize: 10,
    lineHeight: 1.2,
    letterSpacing: 1.5,
    fontWeight: ({ bold }) => (bold ? 700 : 400),
  },
}))

const RowField = ({ label, helperText, value, bold, underline }) => {
  const classes = useStyles({ bold })

  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography className={[classes.lightText, classes.text]}>{label}</Typography>
        <Typography className={classes.text}>{value}</Typography>
      </Box>
      {helperText && <Typography className={classes.helperText}>{helperText}</Typography>}
      {underline && <Divider className={classes.divider} />}
    </Box>
  )
}

export default RowField
