/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Slide,
  Box,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'

import { useTranslate } from '../../lib/translate'
import {
  getBackendCustomerMessagesData,
  isBackendDownloadFileFetching,
} from '../../redux/selectors'
import { fetchSetCustomerMessagesActions, fetchDownloadFileActions } from '../../redux/actions'
import { CUSTOMER_MESSAGE_STATUS } from '../../config'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
  dialogContent: {
    paddingBottom: theme.spacing(3),
    paddingTop: 0,
  },
  newMsgIndicator: {
    width: 2,
    backgroundColor: theme.palette.secondary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  messageTitle: props => ({
    fontWeight: props.isNew ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
  }),
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide unmountOnExit direction="up" ref={ref} {...props} />
})

const SingleMessage = ({ title, text, status, contentDocumentVersionId }) => {
  const dispatch = useDispatch()
  const isFetchingDownload = useSelector(isBackendDownloadFileFetching)
  const isNew = status === CUSTOMER_MESSAGE_STATUS.NEW
  const classes = useStyles({ isNew })
  const [loading, setLoading] = useState(false)

  const handleDownload = () => {
    if (contentDocumentVersionId) {
      setLoading(true)
      dispatch(
        fetchDownloadFileActions.requestAction({
          contentDocumentVersionId,
          name: `${title}.pdf`,
        })
      )
    }
  }

  useEffect(() => {
    if (!isFetchingDownload) {
      setLoading(false)
    }
  }, [isFetchingDownload])

  return (
    <ListItem button onClick={handleDownload}>
      {isNew && <Divider orientation="vertical" absolute className={classes.newMsgIndicator} />}
      <ListItemText
        disableTypography
        primary={
          <Typography variant="h6" className={classes.messageTitle}>
            {title}
          </Typography>
        }
        secondary={<div dangerouslySetInnerHTML={{ __html: text }} />}
      />
      {loading && <CircularProgress color="secondary" />}
    </ListItem>
  )
}

const _CustomDialogContent = ({ messages, fetchSetCustomerMessages }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const sortedMessages = orderBy(messages, 'createdDate', 'desc')

  useEffect(() => {
    return () => {
      const unreadMessages = filter(messages, { status: CUSTOMER_MESSAGE_STATUS.NEW })
      if (!isEmpty(unreadMessages)) {
        fetchSetCustomerMessages({
          notifications: unreadMessages.map(msg => msg.id),
          newStatus: CUSTOMER_MESSAGE_STATUS.READ,
        })
      }
    }
  }, [fetchSetCustomerMessages, messages])

  return (
    <DialogContent className={classes.dialogContent}>
      {isEmpty(messages) ? (
        <Box mt={2}>
          <Typography align="center" variant="h6">
            {translate('customerMessages.noMessages')}
          </Typography>
        </Box>
      ) : (
        <List>
          {sortedMessages.map((msg, i) => (
            <SingleMessage {...msg} key={`single-msg-${i}`} />
          ))}
        </List>
      )}
    </DialogContent>
  )
}

const mapStateToProps = state => ({
  messages: getBackendCustomerMessagesData(state),
})

const mapDispatchToProps = {
  fetchSetCustomerMessages: fetchSetCustomerMessagesActions.requestAction,
}

const CustomDialogContent = connect(mapStateToProps, mapDispatchToProps)(_CustomDialogContent)

const CustomerMessagesDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle disableTypography style={{ paddingBottom: 0 }}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {translate('userProfile.appBar.messages')}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <CustomDialogContent />
    </Dialog>
  )
}

export default CustomerMessagesDialog
