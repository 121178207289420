import moment from 'moment'

export const availableMonths = () => {
  const nextAvailableMonth = moment().add(5, 'days').add(1, 'M').startOf('month')
  const listOfAvailableMonths = [
    nextAvailableMonth,
    moment(nextAvailableMonth).add(1, 'M'),
    moment(nextAvailableMonth).add(2, 'M'),
    moment(nextAvailableMonth).add(3, 'M'),
    moment(nextAvailableMonth).add(4, 'M'),
    moment(nextAvailableMonth).add(5, 'M'),
  ]

  return listOfAvailableMonths
}
