import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { CardMedia, Box, makeStyles, LinearProgress } from '@material-ui/core'

import { ROUTES } from '../../config'
import { fetchUserDataActions } from '../../redux/actions'
import { isBackendUserFetching, getBackendUserError, getBackendToken } from '../../redux/selectors'
import { usePrevious } from '../../lib/miscellaneous'
import useAppLogo from '../../lib/customHooks/useAppLogo'
import { getReferralFromUrl, storeReferralInSessionStorage } from '../../lib/util'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '20rem',
    marginBottom: theme.spacing(3),
  },
  progress: {
    width: '20rem',
    backgroundColor: '#b78f00',
    height: 1,
  },
}))

const LandingPageRedirect = ({ fetchUser, isFetching, error, hasToken }) => {
  const { search } = useLocation()
  const classes = useStyles()
  const appLogo = useAppLogo()
  const wasPreviouslyFetching = usePrevious(isFetching)
  const loginRoute = `${ROUTES.LOGIN}${search || ''}`

  const referral = getReferralFromUrl();
  if (referral) storeReferralInSessionStorage(referral);

  useEffect(() => {
    if (hasToken) {
      fetchUser()
    }
  }, [hasToken, fetchUser])

  if (!hasToken) {
    return <Redirect to={loginRoute} />
  }

  if (wasPreviouslyFetching && !isFetching) {
    if (error) {
      return <Redirect to={loginRoute} />
    }
    return <Redirect to={ROUTES.DASHBOARD} />
  }

  return (
    <Box className={classes.container}>
      <CardMedia component="img" image={appLogo} alt="App Logo" className={classes.logo} />
      <LinearProgress className={classes.progress} />
    </Box>
  )
}

const mapStateToProps = state => ({
  isFetching: isBackendUserFetching(state),
  error: getBackendUserError(state),
  hasToken: Boolean(getBackendToken(state)),
})

const mapDispatchToProps = {
  fetchUser: fetchUserDataActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageRedirect)
