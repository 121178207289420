import { combineReducers } from 'redux'

import queryReducer from './queryReducer'
import mutationReducer from './mutationReducer'

// List of all backend reducers
const backendReducerList = {
  query: queryReducer,
  mutation: mutationReducer,
}

/**
 * The backend reducer just combines all sub reducers
 */
const backendReducer = combineReducers(backendReducerList)

export default backendReducer
