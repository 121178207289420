import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import get from 'lodash/get'

import { useTranslate } from '../../lib/translate'
import { FormLayout, TextFieldAdapter, CustomSaveButton, PasswordSafetyIndicator } from '..'
import { validatePassword, validateConfirmPassword } from '../../lib/validation'
import { fetchUpdatePasswordActions } from '../../redux/actions'
import {
  isBackendUpdatePasswordFetching,
  getBackendUpdatePasswordError,
} from '../../redux/selectors'
import useSnackbar from '../../lib/useSnackbar'
import { usePrevious } from '../../lib/miscellaneous'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  topCard: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  useOldAccountLabel: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2.fontSize,
  },
  bottomContents: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  bottomSpacing: {
    marginBottom: theme.spacing(),
  },
  passwordSafetyContainer: {
    display: 'grid',
    gridTemplateColumns: '25% auto',
    marginBottom: theme.spacing(2),
  },
  passwordHint: {
    color: theme.palette.text.hint,
  },
}))

const UpdatePasswordPage = ({ updatePassword, isFetching, error }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const { token } = useParams()
  const showSnackbar = useSnackbar()
  const wasPreviouslyFetching = usePrevious(isFetching)

  const onSubmit = ({ password }) => {
    updatePassword({ token, password, history })
  }

  useEffect(() => {
    if (wasPreviouslyFetching && !isFetching && error) {
      showSnackbar(error, 'error')
    }
  }, [isFetching, error, wasPreviouslyFetching, showSnackbar])

  return (
    <FormLayout>
      <Box>
        <Typography variant="h6" gutterBottom className={classes.textBold}>
          {translate('updatePassword.title')}
        </Typography>
        <Typography variant="subtitle2" paragraph>
          {translate('updatePassword.subtitle')}
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={validateConfirmPassword}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.topCard}>
                <CardContent className={classes.cardContentPadding}>
                  <Box>
                    <Field
                      name="password"
                      component={TextFieldAdapter}
                      validate={validatePassword}
                      fullWidth
                      type="password"
                      label={translate('formFields.pass')}
                    />
                    <Field
                      name="confirmPassword"
                      component={TextFieldAdapter}
                      fullWidth
                      type="password"
                      label={translate('formFields.confirmPassword')}
                    />
                    <Box className={classes.passwordSafetyContainer}>
                      <Typography variant="subtitle2">{translate('register.security')}</Typography>
                      <PasswordSafetyIndicator password={get(values, 'password')} />
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" paragraph className={classes.passwordHint}>
                        {translate('register.passwordHint')}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Box className={classes.bottomContents}>
                <CustomSaveButton loading={isFetching}>
                  {translate('actions.save')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

UpdatePasswordPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  updatePassword: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isFetching: isBackendUpdatePasswordFetching(state),
  error: getBackendUpdatePasswordError(state),
})

const mapDispatchToProps = {
  updatePassword: fetchUpdatePasswordActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage)
