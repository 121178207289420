export const TITLE_OPTIONS = {
  NO_TITLE: {
    key: 'titleOptions.noSelection',
    value: '',
  },
  PROF: {
    key: 'titleOptions.prof',
    value: 'Prof.',
  },
  DR: {
    key: 'titleOptions.dr',
    value: 'Dr.',
  },
  PROF_DR: {
    key: 'titleOptions.profDr',
    value: 'Prof. Dr.',
  },
  B_ENG: {
    key: 'titleOptions.bEng',
    value: 'B.Eng.',
  },
  BA: {
    key: 'titleOptions.ba',
    value: 'BA',
  },
  BBA: {
    key: 'titleOptions.bba',
    value: 'BBA',
  },
  BED: {
    key: 'titleOptions.bed',
    value: 'BEd',
  },
  BSC: {
    key: 'titleOptions.bsc',
    value: 'BSc',
  },
  DDR: {
    key: 'titleOptions.ddr',
    value: 'DDr.',
  },
  DIPL_ING: {
    key: 'titleOptions.diplIng',
    value: 'Dipl. Ing.',
  },
  DIPL_ING_FH: {
    key: 'titleOptions.diplIngFh',
    value: 'Dipl. Ing. (FH)',
  },
  DIPL_PAD: {
    key: 'titleOptions.diplPad',
    value: 'Dipl. Päd.',
  },
  DIR: {
    key: 'titleOptions.dir',
    value: 'Dir.',
  },
  ING: {
    key: 'titleOptions.ing',
    value: 'Ing.',
  },
  MA: {
    key: 'titleOptions.ma',
    value: 'MA',
  },
  MAG: {
    key: 'titleOptions.mag',
    value: 'Mag.',
  },
  MAG_FH: {
    key: 'titleOptions.magFh',
    value: 'Mag. (FH)',
  },
  MBA: {
    key: 'titleOptions.mba',
    value: 'MBA',
  },
  MIB: {
    key: 'titleOptions.mib',
    value: 'MIB',
  },
  MSC: {
    key: 'titleOptions.msc',
    value: 'MSc',
  },
  PHD: {
    key: 'titleOptions.phd',
    value: 'PhD',
  },
  UNIV_PROV: {
    key: 'titleOptions.univProv',
    value: 'Univ. Prov',
  },
}
