import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const CustomRoute = ({ children, title, ...rest }) => (
  <>
    {title && (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    )}
    <Route {...rest}>{children}</Route>
  </>
)

export default CustomRoute
