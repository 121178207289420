import { get } from 'lodash'
import { useMemo } from 'react'
import { API_ERROR_KEYS } from '../../config'
import { useTranslate } from '../translate'

const useTranslateApiError = () => {
  const translate = useTranslate()

  const translateApiError = useMemo(
    () => error => {
      const errorExtensions = get(error, 'graphQLErrors[0].extensions')
      const code = get(errorExtensions, 'code')

      if (code === API_ERROR_KEYS.SALESFORCE_ERROR) {
        if (get(errorExtensions, '[0].errorCode') === API_ERROR_KEYS.CUSTOM_VALIDATION_EXCEPTION) {
          const customValidationMessage = get(errorExtensions, '[0].message')
          return customValidationMessage ?? translate('errorKeys.default')
        } else {
          const nestedCode = get(errorExtensions, 'extensions.code')

          return nestedCode ? translate(`errorKeys.${nestedCode}`) : translate('errorKeys.default')
        }
      } else {
        return code ? translate(`errorKeys.${code}`) : translate('errorKeys.default')
      }
    },
    [translate]
  )

  return translateApiError
}

export default useTranslateApiError
