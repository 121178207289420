import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  CardMedia,
  Collapse,
  Hidden,
  Box,
  Typography,
  Divider,
} from '@material-ui/core'
import { useLocation, useHistory } from 'react-router-dom'
import some from 'lodash/some'

import { useTranslate } from '../../lib/translate'
import { ROUTES, DRAWER_MAIN_ITEMS } from '../../config'
import { appLogout } from '../../redux/actions'
import { getBackendAccountBrandData, getBackendCountryDepartmentData } from '../../redux/selectors'
import useSnackbar from '../../lib/useSnackbar'
import useDrawerGreyItems from '../../lib/customHooks/useDrawerGreyItems'
import useAppLogo from '../../lib/customHooks/useAppLogo'
import useIsUnderaged from '../../lib/customHooks/useIsUnderaged'

export const DRAWER_WIDTH = 230

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    boxShadow: '0px 5px 30px 0px rgba(209, 169, 108, 0.15)',
    borderWidth: 0,
  },
  logoToolbar: {
    maxHeight: '6rem',
    maxWidth: '100%',
    objectFit: 'scale-down',
    cursor: 'pointer',
  },
  itemsStyle: {
    color: theme.palette.primary.main,
  },
  activeItemStyleBold: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  activeItemStyle: {
    color: theme.palette.secondary.main,
  },
  greyText: {
    color: theme.palette.text.hint,
  },
  activeItemIndicator: {
    opacity: 0.5,
    marginRight: theme.spacing(4),
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    display: 'block',
  },
}))

const DrawerLogo = () => {
  const classes = useStyles()
  const history = useHistory()
  const appLogo = useAppLogo()
  const { pathname } = useLocation()
  const handleLogoClick = () => {
    if (pathname !== ROUTES.DASHBOARD) {
      history.push(ROUTES.DASHBOARD)
    }
  }
  return (
    <Box mb={2} p={2} pt={4}>
      <Toolbar disableGutters>
        <CardMedia
          component="img"
          alt="App Logo"
          image={appLogo}
          className={classes.logoToolbar}
          onClick={handleLogoClick}
        />
      </Toolbar>
    </Box>
  )
}

const _DrawerMainItems = ({ logout, closeDrawer, countryDepartment }) => {
  const { pathname } = useLocation()
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const isUnderaged = useIsUnderaged()

  const showSnackbar = useSnackbar()

  const [selectedName, setSelectedName] = useState('')
  const handleSelect = name => {
    selectedName === name ? setSelectedName('') : setSelectedName(name)
  }
  const closeCollapse = () => {
    setSelectedName('')
  }

  const handleOnItemClick = item => {
    item.isNotAvailable && showSnackbar('notification.featureNotYetAvailable')
    item.isLogout && logout()
    item.items ? handleSelect(item.name) : closeCollapse()
    if (item.link) {
      handleOnRedirect(item)
    }
  }

  const handleOnRedirect = item => {
    if (item.isExternalLink) {
      window.open(item.link, '_blank')
    } else {
      history.push(item.link)
      closeDrawer && closeDrawer()
    }
  }

  return DRAWER_MAIN_ITEMS.map(item => {
    const isActiveRoute = item.link === pathname
    const hasActiveSubItem = some(item.items, { link: pathname })

    if (item.countryDepartment && countryDepartment && countryDepartment[item.countryDepartment]) {
      item.link = countryDepartment[item.countryDepartment]
    }

    return (
      <div key={item.name}>
        <ListItem
          button
          alignItems="flex-start"
          onClick={() => handleOnItemClick(item)}
          disabled={item.disableUnderaged && isUnderaged}
        >
          <ListItemText
            primary={translate(item.name)}
            classes={{
              primary: isActiveRoute
                ? classes.activeItemStyleBold
                : hasActiveSubItem
                ? classes.activeItemStyle
                : classes.itemsStyle,
            }}
            secondary={
              isActiveRoute || hasActiveSubItem ? (
                <Divider component="span" className={classes.activeItemIndicator} />
              ) : null
            }
          />
        </ListItem>
        <Collapse in={item.name === selectedName} timeout="auto" unmountOnExit>
          {item.items ? (
            <List component="div" disablePadding>
              {item.items.map(subItem => (
                <ListItem
                  button
                  onClick={() => handleOnRedirect(subItem)}
                  key={subItem.name}
                  alignItems="flex-start"
                  disabled={subItem.disableUnderaged && isUnderaged}
                >
                  <ListItemText
                    inset
                    primary={translate(subItem.name)}
                    classes={{
                      primary:
                        subItem.link === pathname
                          ? classes.activeItemStyleBold
                          : classes.itemsStyle,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ) : null}
        </Collapse>
      </div>
    )
  })
}

const mapDispatchToPropsMainItems = {
  logout: appLogout,
}

const mapStateToPropsMainItems = state => ({
  countryDepartment: getBackendCountryDepartmentData(state),
})

const DrawerMainItems = connect(
  mapStateToPropsMainItems,
  mapDispatchToPropsMainItems
)(_DrawerMainItems)

const DrawerGreyItems = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const items = useDrawerGreyItems()

  return (
    <Box mt={4} className={classes.greyText}>
      {items.map(item => (
        <ListItem button key={item.key} dense onClick={item.handleClick}>
          <ListItemText className={classes.greyText} primary={translate(item.key)} />
        </ListItem>
      ))}
    </Box>
  )
}

const _DrawerContacts = ({ countryDepartment, brand }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { companyName } = brand || {}
  const { phone, emspEmail, streetHouseNr, postalCodeCity } = countryDepartment || {}

  return (
    <Box p={2} className={classes.greyText}>
      {companyName ? (
        <>
          <Typography gutterBottom>{companyName}</Typography>
          <Typography variant="caption" component="p">
            {translate('drawer.cooperation')}
          </Typography>
          <Typography variant="caption" component="p" paragraph>
            {translate('company.name')}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="caption" component="p">
            {translate('company.name')}
          </Typography>
          <Typography variant="caption" component="p">
            {streetHouseNr}
          </Typography>
          <Typography variant="caption" component="p" paragraph>
            {postalCodeCity}
          </Typography>
          <Typography variant="caption" component="p">
            {`${translate('drawer.drawerContact.tel')}: ${phone}`}
          </Typography>
        </>
      )}
      <Typography variant="caption" component="p" paragraph>
        {`${translate('formFields.mail')}: ${emspEmail}`}
      </Typography>
      <Typography variant="caption" component="p">
        {translate('drawer.drawerContact.copyright')}
      </Typography>
    </Box>
  )
}

const mapStateToPropsContacts = state => ({
  countryDepartment: getBackendCountryDepartmentData(state),
  brand: getBackendAccountBrandData(state),
})

const DrawerContacts = connect(mapStateToPropsContacts)(_DrawerContacts)

const CustomDrawer = ({ open, close }) => {
  const classes = useStyles()

  return (
    <nav className={classes.drawer} aria-label="Navigation drawer">
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={close}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerLogo />
          <List>
            <DrawerMainItems closeDrawer={close} />
            <DrawerGreyItems />
            <DrawerContacts />
          </List>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerLogo />
          <List>
            <DrawerMainItems />
            <DrawerGreyItems />
            <DrawerContacts />
          </List>
        </Drawer>
      </Hidden>
    </nav>
  )
}

CustomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}

export default CustomDrawer
