import { createStore as reduxCreateStore, applyMiddleware, compose, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'

import rootSaga from './rootSaga'
import appReducer from './app/reducer'
import backendReducer from './backend/reducer'
import formReducer from './form/reducer'

// List of all reducers
export const reducers = {
  app: appReducer,
  backend: backendReducer,
  form: formReducer,
}

const createStore = () => {
  // combine all reducers
  const reducer = combineReducers(reducers)

  // compose with devtools if possible
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()
  const sagaEnhancer = applyMiddleware(sagaMiddleware)

  // compose all enhancers
  const enhancers = [sagaEnhancer]
  const enhancer = composeEnhancers(...enhancers)

  // create the redux store
  const store = reduxCreateStore(reducer, undefined, enhancer)
  // persist the redux store
  const persistor = persistStore(store)

  // run the saga
  sagaMiddleware.run(rootSaga)

  // return the store and persistor for the provider in App.js
  return { store, persistor }
}

export default createStore
