import { ROUTES } from './constants'
import config from './config'

export const DRAWER_MAIN_ITEMS = [
  {
    name: 'drawer.drawerLink.dashboard',
    link: ROUTES.DASHBOARD,
  },
  {
    name: 'drawer.drawerLink.plan',
    items: [
      {
        name: 'drawer.drawerLink.plansOverview',
        link: ROUTES.SAVINGSPLANS_OVERVIEW,
      },
      {
        name: 'drawer.drawerLink.plansRequests',
        link: ROUTES.REQUESTS,
      },
      {
        name: 'drawer.drawerLink.payments',
        link: ROUTES.PAYMENTS,
      },
    ],
  },
  {
    name: 'drawer.drawerLink.service',
    items: [
      {
        name: 'drawer.drawerLink.contractCancellation',
        link: ROUTES.CANCEL_SAVINGS_PLAN,
        disableUnderaged: true,
      },
      {
        name: 'drawer.drawerLink.contractChange',
        link: ROUTES.PLAN_ADJUSTMENT_STEP_ONE,
        disableUnderaged: true,
      },
    ],
  },
  {
    name: 'drawer.drawerLink.conto',
    items: [
      {
        name: 'drawer.drawerLink.data',
        link: ROUTES.PROFILE,
      },
      {
        name: 'drawer.drawerLink.security',
        link: ROUTES.SECURITY,
      },
    ],
  },
  {
    name: 'drawer.drawerLink.faq',
    countryDepartment: 'faqUrl',
    link: config.externalRoutes.faq,
    isExternalLink: true,
  },
  {
    name: 'drawer.drawerLink.logout',
    link: ROUTES.LOGIN,
    isLogout: true,
  },
]

if (!config.isDemo) {
  DRAWER_MAIN_ITEMS[2].items.push({
    name: 'drawer.drawerLink.migration',
    link: ROUTES.MIGRATION,
  })
}
