import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card, CardContent, Typography, Button } from '@material-ui/core'

import { useTranslate } from '../../lib/translate'
import { FormLayout, TextFieldAdapter, CustomSaveButton } from '..'
import { validateEmail } from '../../lib/validation'
import { fetchResetPasswordActions } from '../../redux/actions'
import {
  getBackendResetPasswordData,
  getBackendResetPasswordError,
  isBackendResetPasswordFetching,
} from '../../redux/selectors'
import useSnackbar from '../../lib/useSnackbar'
import { usePrevious } from '../../lib/miscellaneous'

const useStyles = makeStyles(theme => ({
  cardContentPadding: {
    padding: theme.spacing(4),
  },
  card: {
    margin: `${theme.spacing(3.75)}px auto`,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bottomContents: {
    margin: `${theme.spacing(3.75)}px auto`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const ForgotPasswordPage = ({ resetPassword, isFetching, error, successful }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const history = useHistory()
  const showSnackbar = useSnackbar()
  const wasPreviouslyFetching = usePrevious(isFetching)

  const onSubmit = ({ email }) => {
    resetPassword({ email })
  }

  useEffect(() => {
    if (wasPreviouslyFetching && !isFetching) {
      error
        ? showSnackbar(error, 'error')
        : successful && showSnackbar('forgotPassword.successful', 'success')
    }
  }, [isFetching, error, wasPreviouslyFetching, showSnackbar, successful])

  return (
    <FormLayout>
      <Box>
        <Typography variant="h6" gutterBottom className={classes.textBold}>
          {translate('forgotPassword.title')}
        </Typography>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContentPadding}>
                  <Typography variant="body2" paragraph color="primary">
                    {translate('forgotPassword.info')}
                  </Typography>
                  <Field
                    name="email"
                    component={TextFieldAdapter}
                    validate={validateEmail}
                    fullWidth
                    label={translate('formFields.mail')}
                  />
                </CardContent>
              </Card>
              <Box className={classes.bottomContents}>
                <Button variant="text" onClick={() => history.goBack()}>
                  {translate('actions.back')}
                </Button>
                <CustomSaveButton loading={isFetching}>
                  {translate('actions.submit')}
                </CustomSaveButton>
              </Box>
            </form>
          )}
        />
      </Box>
    </FormLayout>
  )
}

ForgotPasswordPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  successful: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isFetching: isBackendResetPasswordFetching(state),
  error: getBackendResetPasswordError(state),
  successful: getBackendResetPasswordData(state),
})

const mapDispatchToProps = {
  resetPassword: fetchResetPasswordActions.requestAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)
