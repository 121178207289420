import { useState, useMemo } from 'react'

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlers = useMemo(
    () => ({
      openPopover: event => {
        setAnchorEl(event.currentTarget)
      },
      closePopover: () => {
        setAnchorEl(null)
      },
    }),
    []
  )

  return [anchorEl, handlers]
}

export default usePopover
