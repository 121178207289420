import React from 'react'
import { connect } from 'react-redux'
import { Switch, useLocation, Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import {
  CustomRoute,
  CancelSavingsPlanPageOne,
  CancelSavingsPlanPageTwo,
  CancelSavingsPlanPageThree,
  CancelSavingsPlanPageFour,
} from '../..'
import { ROUTES } from '../../../config'
import { getFormCancelPlanData } from '../../../redux/selectors'

/**
 * Cancel Plan Navigation
 *
 * Our nested navigation component for the cancel savingsplan workflow
 */
const CancelPlanNavigation = ({ isFormDataMissing }) => {
  const { pathname } = useLocation()

  if (pathname !== ROUTES.CANCEL_SAVINGS_PLAN_STEP_ONE && isFormDataMissing) {
    return <Redirect to={ROUTES.CANCEL_SAVINGS_PLAN_STEP_ONE} />
  }

  return (
    <Switch>
      <CustomRoute path={ROUTES.CANCEL_SAVINGS_PLAN_STEP_ONE}>
        <CancelSavingsPlanPageOne />
      </CustomRoute>
      <CustomRoute path={ROUTES.CANCEL_SAVINGS_PLAN_STEP_TWO}>
        <CancelSavingsPlanPageTwo />
      </CustomRoute>
      <CustomRoute path={ROUTES.CANCEL_SAVINGS_PLAN_STEP_THREE}>
        <CancelSavingsPlanPageThree />
      </CustomRoute>
      <CustomRoute path={ROUTES.CANCEL_SAVINGS_PLAN_STEP_FOUR}>
        <CancelSavingsPlanPageFour />
      </CustomRoute>
    </Switch>
  )
}

const mapStateToProps = state => ({
  isFormDataMissing: isEmpty(getFormCancelPlanData(state)),
})

export default connect(mapStateToProps)(CancelPlanNavigation)
